import { ApplicationController } from "../application_controller";
import swal from 'sweetalert';

export default class extends ApplicationController {
  static values = {
    targetUrl: String
  }

  get #selectedProductIds() {
    return localStorage.getItem('product_ids') ? JSON.parse(localStorage.getItem('product_ids')) : [];
  }

  connect() {}

  submit(event) {
    event.preventDefault();
    event.stopPropagation();
    
    const payload = JSON.stringify({ 
      product_ids: this.#selectedProductIds
    })

    const headers = { 
      "Accept": "application/json",
      "Content-Type": "application/json",
    };

    this.fetchWithToken(this.targetUrlValue, {
      method: 'POST',
      headers: headers,
      body: payload
    })
    .then(response => response.json())
    .then(data => {
      swal({
        title: data.message,
        icon: "info"
      })
      .then(()=> window.location.reload())
    })
  }
}