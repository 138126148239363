import { ApplicationController } from "../../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['btnContainer', 'btn', 'feedBack']

  call() {
    event.preventDefault();
    const text = this.btnTarget.innerText;
    this.feedBackTarget.innerHTML = this.spinnerHtml();
    this.btnContainerTarget.innerHTML = `<button class="btn btn-outline-primary" disabled>${text}</button>`
    this.fetchWithToken(`/orders/${this.orderIdValue}/send_to_ftp`, {
      method: 'POST',
      headers: { "Accept": "application/json" }
    })
    .then(response => response.json())
    .then(data => {
      if (data.current_status_html) this.orderCurrentStatusControllers.forEach(el => el.refresh(data.current_status_html));
      this.rightSideOverlayController.refreshBody(data.order_show_html);
    })
  }

  spinnerHtml() {
    return `
      <div class="spinner-border spinner-border-sm text-primary ml-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    `
  }
}
