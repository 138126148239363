import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "link" ]

  navigate(event) {
    const linkAncestor = event.path.find(el => el.tagName === "A");
    if (!linkAncestor) this.linkTarget.click();
  }
}
