import { ApplicationController } from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['selectedProductIds', 'inSelectionCheckbox']

  connect() {
    this.element[this.identifier] = this;
    setTimeout(() => {
      this.#linkSelectionController()
      this.selectedProductIdsTarget.value = localStorage.getItem('product_ids');
    }, 10)
  }
  
  updateBuddies(event) {
    const formValues = this.#formValues();
    const buddies = this.#otherForms;
    buddies.forEach(buddy => buddy.#uncheckCheckboxes())
    Object.keys(formValues).forEach((inputName) => {
      buddies.forEach(buddy => buddy.#updateInput(inputName, formValues[inputName]))
    })
  }

  showSelection() {
    if (JSON.parse(localStorage.getItem('product_ids')).length > 250) return;
    
    this.#clearForm();
    this.inSelectionCheckboxTarget.checked = true;
    this.selectedProductIdsTarget.value = localStorage.getItem('product_ids');
    this.element.submit();
  }

  #linkSelectionController() {
    const identifier = "local-storage-selection";
    const selectionControllerElement = document.querySelector(`[data-controller^="${identifier}"]`)
    if (selectionControllerElement) {
      const controller = selectionControllerElement[identifier]
      if (!controller) return;
      controller.setFilterFormIdentifierValue(this.identifier);
    } 
  }

  get #otherForms() {
    const forms =  Array.from(document.querySelectorAll(`[data-controller="${this.identifier}"]`))
                        .filter(form => form != this.element);

    return forms.map(form => form[this.identifier])
  }

  #formValues() {
    const formData = new FormData(this.element);
    return Object.fromEntries(formData);
  }

  #clearForm() {
    this.element.reset();
    Array.from(this.element.querySelectorAll('select'))
         .forEach(select => select.selectedIndex = 0)
  }

  #uncheckCheckboxes() {
    Array.from(this.element.querySelectorAll('input[type="checkbox"]'))
         .forEach(checkbox => checkbox.checked = false)
  }

  #updateInput(inputName, inputValue) {
    const input = this.element.querySelector(`[name='${inputName}']`);
    if (input.type === "checkbox") {
      input.checked = inputValue === 'true'
    } else if (input.value == inputValue) { 
      return
    } else {
      input.value = inputValue;
    }
  }
}