const manageTextareaHeight = (textarea) => {
  textarea.insertAdjacentHTML('afterend', `<div class='textarea-buddy form-control'></div>`);
  textarea.addEventListener('change', autosizeTextarea);
  textarea.addEventListener('keydown', autosizeTextarea);
  textarea.addEventListener('keyup', autosizeTextarea);
  textarea.addEventListener('focus', autosizeTextarea);
}

function autosizeTextarea() {
  const buddy = this.nextElementSibling;
  if (!buddy.classList.contains('textarea-buddy')) return;
  buddy.innerHTML = this.value.replace(/\n/g, '<br/>');
}

export { manageTextareaHeight };
