import { ApplicationController } from "../../javascript/controllers/application_controller";
import { initSlimSelects } from '../../javascript/plugins/init_slim_selects';
import consumer from "../../javascript/channels/consumer";

export default class extends ApplicationController {
  static targets = ['ppDataContainer']
  static values = {
    productId: Number
  }

  connect() {
    this.element[this.identifier] = this;
    this.channelIdentifier = { channel: "ProductInNurseryChannel", product_id: this.productIdValue };

    // 👇 si une subscription existe déjà, on ne souscrit pas à nouveau
    const subscriptions = consumer.subscriptions.subscriptions;
    const previousSub = subscriptions.find(sub => {
      const id = JSON.parse(sub.identifier);
      return id.channel === this.channelIdentifier.channel && id.product_id === this.channelIdentifier.product_id
    })
    if (previousSub) return;

    const that = this;
    consumer.subscriptions.create(this.channelIdentifier, {
      received(data) {
        that.ppDataContainerTarget.innerHTML = data.pp_data;
        that.ppDataContainerTarget.querySelector('> div').classList.remove('hidden');
      }
    });
  }

  get productCardsController() {
    const productCards = document.querySelector('[data-controller="product-nursery--product-cards"');
    if (!productCards) return;

    return productCards["product-nursery--product-cards"];
  }
  
  get otherProductCardControllers() {
    const productCards = document.querySelectorAll('[data-controller="product-nursery--product-card"');
    if (productCards.length == 0) return;
    
    return Array.from(productCards).map(productCard => productCard["product-nursery--product-card"]);
  }

  submit() {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);
    this.fetchWithToken(form.action, {
      method: 'PATCH',
      headers: { "Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.element.remove();
        // TODO = display message
      } else {
        this.element.outerHTML = data.product_card;
        initSlimSelects(this.element);
        // TODO = display message
        console.log(data.errors);
      }
      if (data.number_of_products_in_creation == 0) {
        this.productCardsController.showEmptyState();
      } else {
        this.productCardsController.updateMessage(data.products_container_title);
      }

      // 👇 si une catégorie a été ajoutée, on doit mettre à jour les autres forms
      if (data.other_product_cards) {
        Object.keys(data.other_product_cards).forEach(key => {
          const productCardController = this.otherProductCardControllers.find(controller => controller.productIdValue == key);
          if (productCardController) {
            productCardController.element.outerHTML = data.other_product_cards[key];
            initSlimSelects(productCardController.element);
          }
        })
      }
      
      initSlimSelects(this.element);
    });
  }
}