import { Controller } from "stimulus";
// import { fetchWithToken } from '../../../javascript/utils/fetch_with_token';

export default class extends Controller {
  static values = {
    subject: String,
    fetchData: Boolean,
    salingScope: String,
    salingScopeDetail: String
  }

  connect() {
    this.element.controller = this;
    if (this.fetchDataValue) {
      this.fetchData();
    }
  }

  fetchData() {
    fetch("/last_period_sales/saling_scope_data", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        saling_scope: this.salingScopeValue,
        saling_scope_detail: this.salingScopeDetailValue
      })
    })
      .then(response => response.json())
      .then(data => {
        if (this.subjectValue === "counter") {
          this.element.outerHTML = data.counter_html;
        } else {
          this.element.outerHTML = data.revenue_html;
        }
      });
  }
}