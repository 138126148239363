import { Controller } from "stimulus"
import { fetchWithToken } from '../utils/fetch_with_token';

export default class extends Controller {
  static targets = [ "output" ]
  static values = {
    productId: Number,
    picture: String,
    mainPicture: String,
    undesiredPictures: Array
  }

  get allProductImages() {
    return Array.from(document.querySelectorAll('[data-controller="product-image"]'));
  }

  get allProductImageControllers() {
    return this.allProductImages.map(el => el['controller']);
  }

  get isMainPicture() {
    return this.pictureValue == this.mainPictureValue;
  }

  get belongsToUndesired() {
    return this.undesiredPicturesValue.includes(this.pictureValue);
  }

  connect() {
    this.element["controller"] = this;
    this.setElementClass();
  }

  setElementClass() {
    this.element.classList.remove('main');
    this.element.classList.remove('undesired');
    if (this.isMainPicture) this.element.classList.add('main');
    if (this.belongsToUndesired) this.element.classList.add('undesired');
  }

  setAsMain() {
    event.preventDefault();
    const bodyData = { picture: this.isMainPicture ? '' : this.pictureValue };
    fetchWithToken(`/products/${this.productIdValue}/set_main_picture`, {
      method: 'POST',
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(bodyData)
    })
    .then(response => response.json())
    .then(data => {
      if (!data.main_picture) return;
      this.allProductImageControllers.forEach(controller => {
        controller.element.classList.remove('main');
        controller.mainPictureValue = data.main_picture;
        controller.setElementClass();
      });
    })
  }

  setAsUndesired() {
    event.preventDefault();
    const bodyData = { picture: this.pictureValue };
    fetchWithToken(`/products/${this.productIdValue}/add_to_undesired`, {
      method: 'POST',
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(bodyData)
    })
    .then(response => response.json())
    .then(data => {
      if (!data.undesired) return;
      this.allProductImageControllers.forEach(controller => {
        controller.element.classList.remove('undesired');
        controller.undesiredPicturesValue = data.undesired;
        controller.setElementClass();
      });
    })
  }
}
