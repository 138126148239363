import { Controller } from "stimulus";
import { fetchWithToken } from '../../../javascript/utils/fetch_with_token';
import swal from 'sweetalert';

export default class extends Controller {
  static values = {
    path: String
  }

  delete() {
    fetchWithToken(this.pathValue, {
      method: "delete",
      headers: { "Accept": "application/json" }
    })
    .then(response => response.json())
    .then((data) =>  {
      if (data.success) this.element.remove();
    })
  }

  submit(event) {
    event.preventDefault();
    swal({
      title: "Une dernière petite confirmation 🙏",
      text: "Préalablement à la suppression du champ, vous êtes-vous assuré que celui-ci n'était pas utilisé par ailleur (comme au niveau du paramétrage d'un diffuseur sur Lengow par exemple) ?",
      icon: "warning",
      buttons: true,
    })
    .then(willDelete => {
      if (willDelete) {
        this.delete();
      } else {
        swal("Ouf !!! 😬")
      }
    })
  }
}
