import { Controller } from "stimulus"
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    placeholder: String,
    addable: Boolean
  }

  connect() {
    var options = {
      // placeholder: this.placeholderValue,
      maxOptions: null,
      // allowEmptyOption: false,
    }
    if (this.addableValue) options = { create: true, ...options }
    if (this.placeholderValue) {
      options = { placeholder: this.placeholderValue, ...options }
    } else {
      options = { allowEmptyOption: false, ...options }
    }

    new TomSelect(this.element, {
      render:{
        option_create: function( data, escape ){
          return '<div class="create">Ajouter <strong>' + escape(data.input) + '</strong>&hellip;</div>';
        },
        no_results: function( data, escape ){
          return '<div class="no-results">Aucun résultat trouvé</div>';
        },
      },
      ...options
    })
  }
}
