import { ApplicationController } from "./application_controller";
import SlimSelect from 'slim-select'

export default class extends ApplicationController {
  static values = {
    addable: Boolean,
    hideSearch: Boolean,
  }
  
  connect() {
    var options = {
      select: this.element,
      allowDeselectOption: true
    }

    if (this.addableValue) options = { addable: (value) => value, ...options }
    if (this.hideSearchValue) options = { showSearch: false, ...options }

    this.slimSelect = new SlimSelect(options);
  }
}
