import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "removeEmptyColumnsInput", "removeEmptyColumnsWrapper" ]

  manageRemoveEmptyColumns() {
    if (event.currentTarget.checked) {
      this.removeEmptyColumnsWrapperTarget.classList.remove('hidden');
    } else {
      this.removeEmptyColumnsWrapperTarget.classList.add('hidden');
      this.removeEmptyColumnsInputTarget.checked = false;
    }
  }
}
