import { Controller } from "stimulus"
import { fetchWithToken } from '../../utils/fetch_with_token';
import swal from 'sweetalert';

export default class extends Controller {
  static targets = ['input', 'cta']

  connect() {
    this.element['controller'] = this;
    if (!this.selectedIdsJSON) this.hideCtas();
  }

  showCtas() {
    this.ctaTargets.forEach(cta => cta.classList.remove('d-none'));
  }

  hideCtas() {
    this.ctaTargets.forEach(cta => cta.classList.add('d-none'));
  }

  get selectedIdsJSON() {
    return sessionStorage.getItem('products_ids');
  }

  get filtersForm() {
    return document.querySelector('form.js-filters-form');
  }

  get filtersFormSubmit() {
    return this.filtersForm.querySelector('.js-filters-form-submit');
  }

  get selectionFilterContainer() {
    return this.filtersForm.querySelector('.js-selection-filter-container');
  }

  get productContentFilters() {
    return this.filtersForm.querySelectorAll('.js-product-content-filters-container select');
  }

  get fieldContentFilters() {
    return this.filtersForm.querySelectorAll('.js-field-content-filters-container input');
  }

  get importFilter() {
    return this.filtersForm.querySelector('.js-import-filter-container input');
  }

  submit() {
    event.preventDefault();
    this.inputTarget.value = this.selectedIdsJSON;
    this.productContentFilters.forEach(select => select.value = null);
    this.fieldContentFilters.forEach(input => input.value = null);
    this.importFilter.value = null;
    this.selectionFilterContainer.innerHTML = "";
    this.selectionFilterContainer.appendChild(this.inputTarget.cloneNode(true));
    this.filtersFormSubmit.click();
  }

  setPublishable() {
    event.preventDefault();
    const bodyData = {
      product_ids: this.selectedIdsJSON,
      recipient: event.currentTarget.dataset.recipient,
      publishable: event.currentTarget.dataset.publishable
    }
    fetchWithToken(`/products/selection_publishable`, {
      method: 'POST',
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(bodyData)
    })
    .then(response => response.json())
    .then(data => {
      swal({
        title: data.message,
        text: "Ça ne change pas grand chose pour vous 🙂 Vous serez prévenu dès que l'opération sera terminée !",
        icon: "info",
      })
    })
  }

  translate() {
    event.preventDefault();
    const bodyData = { product_ids: this.selectedIdsJSON }
    fetchWithToken(`/products/translate_selection`, {
      method: 'POST',
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(bodyData)
    })
    .then(response => response.json())
    .then(data => {
      let message = data.message;
      if (JSON.parse(this.selectedIdsJSON).length <= 5) {
        message += " La fin de la traduction vous sera notifiée ici."
      } else {
        message += " La fin de la traduction vous sera notifiée par mail."
      }
      swal({
        title: message,
        icon: "info",
      })
    })
  }

  updatePictures() {
    event.preventDefault();
    const bodyData = { product_ids: this.selectedIdsJSON }
    fetchWithToken(`/products/update_pictures_for_selection`, {
      method: 'POST',
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(bodyData)
    })
    .then(response => response.json())
    .then(data => {
      swal({
        title: data.message,
        icon: "info"
      })
    })
  }

  udapePPInfos() {
    event.preventDefault();
    const bodyData = { product_ids: this.selectedIdsJSON }
    fetchWithToken(`/products/update_pp_infos_for_selection`, {
      method: 'POST',
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(bodyData)
    })
    .then(response => response.json())
    .then(data => {
      swal({
        title: data.message,
        icon: "info",
      })
    })
  }

  sendProductToWebsite(event) {
    event.preventDefault();
    var formData = new FormData(event.currentTarget);
    formData.append('website', event.currentTarget.dataset.website)
    JSON.parse(this.selectedIdsJSON).forEach(id => formData.append('product_ids[]', id))
    fetchWithToken(`/products/send_selection_to_website`, {
      method: 'POST',
      headers: { "Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      swal({
        title: data.message,
        icon: "info",
        text: data.text
      })
      .then(() => {
        window.location.reload();
      })
    })
  }


}
