import SlimSelect from 'slim-select'

const initSlimSelects = (container = null) => {
  const parent = container ? container : document;

  const addableSlimSelects = parent.querySelectorAll('select.addable-slim-select');
  addableSlimSelects.forEach(el => {
    new SlimSelect({
      select: el,
      addable: (value) => value,
      allowDeselectOption: true
    });
  })

  const slimSelects = parent.querySelectorAll('select.slim-select');
  slimSelects.forEach(el => {
    new SlimSelect({
      select: el,
      allowDeselectOption: true
    });
  })
}

export { initSlimSelects };
