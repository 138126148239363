import { Controller } from "stimulus"
import { fetchWithToken } from '../utils/fetch_with_token';

export default class extends Controller {
  static values = {
    path: String
  }

  revert() {
    console.log("Let's revert!!");
    this.element.innerHTML = `
      <div class="spinner-border spinner-border-sm mr-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      Revert en cours...
    `
    fetchWithToken(this.pathValue, {
      method: "post",
      headers: { "Accept": "application/json" }
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if (data.success) {
        const link = document.createElement('a');
        link.setAttribute('href', data.products_path);
        link.click();
      } else {
        const message = `L'import n'a pas pu être 'revert'. Le message d'erreur remonté est : ${data.message}. (si le message n'aide pas à résoudre le problème, n'hésitez pas à contacter le développeur du site)`;
        swal({
          title: "Une erreur est survenue!",
          text: message,
          icon: "error",
        })
        .then(value => {
          this.processBtnTarget.innerHTML = "Importer";
        })
      }
    })
  }

  submit() {
    event.preventDefault();
    swal({
      title: "Une dernière petite confirmation 🙏",
      text: "Juste pour être sûr que vous n'avez pas appuyer sur le bouton par mégarde : tous les produits modifiés par cet import se retrouveront dans l'état dans lequel ils étaient avant l'import.",
      icon: "warning",
      buttons: true,
    })
    .then(willRevert => {
      if (willRevert) {
        this.revert();
      } else {
        swal("On a bien fait de poser la question on dirait ! 😬")
      }
    })
  }
}
