import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selectAllCheckBox", "itemCheckBox", "label", "actions", "sendToWooCommerceModals", "showSelectionBtn"]
  static values = { 
    allDisplayedIds: Array,
    selectableType: String,
    singularLabel: String,
    pluralLabel: String,
    allSelectedLabel: String,
    filterFormIdentifier: String
  }
  
  connect() { 
    this.element[this.identifier] = this;
    this.showSelectionBtnDefaultText = this.showSelectionBtnTarget.innerText;
    this.#refresh()
  }
  
  toggleAllDisplayed(event) {
    this.#removeFromSelection(['all'])
    this.#areAllDisplayedItemsSelected ? this.#removeFromSelection(Array.from(new Set(this.allDisplayedIdsValue))) : this.#addToSelection(this.allDisplayedIdsValue);
    this.#refresh();
  }  
  
  toggleItem(event) {
    event.preventDefault();
    this.#removeFromSelection(['all'])
    document.getSelection().removeAllRanges();
    const currentIndex = this.#itemCheckBoxIndex(event.currentTarget);
    const id = parseInt(event.currentTarget.dataset[this.#itemDataSet]);
    if (event.shiftKey) {
      const minIndex = Math.min(currentIndex, this.lastSelectedItemIndex);
      const maxIndex = Math.max(currentIndex, this.lastSelectedItemIndex);
      const targetsIds = this.itemCheckBoxTargets.slice(minIndex, maxIndex + 1)
      .map(cB => parseInt(cB.dataset[this.#itemDataSet]));
      this.#isSelected(id) ? this.#removeFromSelection(targetsIds) : this.#addToSelection(targetsIds);
    } else {
      this.#isSelected(id) ? this.#removeFromSelection([id]) : this.#addToSelection([id]);
    }
    this.#refresh();
    this.lastSelectedItemIndex = this.#itemCheckBoxIndex(event.currentTarget);
  }
  
  clear(event) {
    localStorage.removeItem(this.#localStorageItemName);
    this.#refresh();
  }
  
  selectAll(event) {
    // localStorage.removeItem(this.#localStorageItemName);
    fetch(window.location.pathname + window.location.search, { headers: { Accept: 'application/json' }})
    .then(response => response.json())
    .then((data) => {
      this.#addToSelection(data.product_ids);
      this.#refresh();
    })
  }
  
  showSelection(event) {
    if (!this.#filterFormController) return;
    this.#filterFormController.showSelection();
  }
  
  setFilterFormIdentifierValue(identifier) {
    this.filterFormIdentifierValue = identifier;
  }
  
  get #filterFormController() {
    const controllerElement = document.querySelector(`[data-controller^="${this.filterFormIdentifierValue}"]`)
    if (!controllerElement) return;
    return controllerElement[this.filterFormIdentifierValue];
  }
  
  get #selectionIsEmpty() {
    return this.#selectedIds.length == 0;
  }
  
  get #selectedIds() {
    return localStorage.getItem(this.#localStorageItemName) ? JSON.parse(localStorage.getItem(this.#localStorageItemName)) : [];
  }
  
  get #areAllDisplayedItemsSelected() {
    return this.allDisplayedIdsValue.every(id => this.#selectedIds.includes(id));
  }
  
  #isSelected(id) {
    return this.#selectedIds.includes(id)
  }
  
  #refresh() {
    this.#setSelectAllCheckBoxIcon();
    this.#setItemCheckBoxesIcons();
    this.#updateLabel();
    this.#displayActions();
  }
  
  #displayActions() {
    if (!this.hasActionsTarget) return;
    
    if (this.#selectionIsEmpty) {
      this.actionsTargets.forEach(target => target.classList.add('d-none'));
    } else {
      this.actionsTargets.forEach(target => target.classList.remove('d-none'));
      this.showSelectionBtnTarget.innerText = `${this.showSelectionBtnDefaultText}${this.#selectedIds.length > 250 ? ' (max. 250)' : ''}`;
      if (this.#selectedIds.length > 250) {
        this.showSelectionBtnTarget.classList.add('line-through')
        this.showSelectionBtnTarget.classList.remove('cursor-pointer')
        this.showSelectionBtnTarget.classList.add('cursor-default')
        this.showSelectionBtnTarget.classList.remove('text-sm')
        this.showSelectionBtnTarget.classList.add('text-xs')
      } else {
        this.showSelectionBtnTarget.classList.remove('line-through')
        this.showSelectionBtnTarget.classList.add('cursor-pointer')
        this.showSelectionBtnTarget.classList.remove('cursor-default')
        this.showSelectionBtnTarget.classList.add('text-sm')
        this.showSelectionBtnTarget.classList.remove('text-xs')
      }
    }
  }
  
  #updateLabel() {
    if (!this.hasLabelTarget) return;
    
    var label
    if (this.#selectedIds.length > 1) {
      label = `${this.#selectedIds.length} ${this.pluralLabelValue}`
    } else if (this.#selectedIds.length === 1) {
      label = `1 ${this.singularLabelValue}`
    } else {
      label = `Aucun ${this.singularLabelValue}`
    }
    this.labelTarget.innerHTML = label;
  }
  
  #setSelectAllCheckBoxIcon() {
    if (!this.hasSelectAllCheckBoxTarget) return;

    if (this.#selectedIds.length === 0) {
      this.selectAllCheckBoxTarget.innerHTML = '<i class="far fa-square text-gray-400"></i>';
    } else if (this.#areAllDisplayedItemsSelected) {
      this.selectAllCheckBoxTarget.innerHTML = '<i class="far fa-check-square text-gray-600"></i>';
    } else {
      this.selectAllCheckBoxTarget.innerHTML = '<i class="far fa-minus-square text-gray-500"></i>';
    }
  }
  
  #setItemCheckBoxesIcons() {
    this.itemCheckBoxTargets.forEach(cB => {
      if (this.#isSelected(parseInt(cB.dataset[this.#itemDataSet]))) {
        cB.innerHTML = '<i class="far fa-check-square text-gray-600"></i>';
      } else {
        cB.innerHTML = '<i class="far fa-square text-gray-400"></i>';
      }
    });
  }
  
  #itemCheckBoxIndex(target) {
    return this.itemCheckBoxTargets.indexOf(target);
  }
  
  #addToSelection(toAddIds) {
    const newSelection = Array.from(new Set([...this.#selectedIds, ...toAddIds]));
    localStorage.setItem(this.#localStorageItemName, JSON.stringify(newSelection));
  }
  
  #removeFromSelection(toRemoveIds) {
    const newSelection = this.#selectedIds.filter(id => !toRemoveIds.find(toRemoveId => toRemoveId === id));
    localStorage.setItem(this.#localStorageItemName, JSON.stringify(newSelection));
  }

  get #itemDataSet() {
    return `${this.selectableTypeValue}Id`
  }

  get #localStorageItemName() {
    return `${this.selectableTypeValue}_ids`
  }
}
