const toggleGraph = () => {
  const toggleButtons = document.querySelectorAll('.toggle-graph');
  toggleButtons.forEach(button => {
    button.addEventListener('click', toggleElement);
  })
}

function toggleElement() {
  const graphContainer = document.getElementById(this.dataset.element);
  graphContainer.classList.toggle('d-none');
  document.querySelectorAll(`[data-element="${this.dataset.element}"] > i`).forEach(el => el.classList.toggle('d-none'));
}

export { toggleGraph };
