function hidePopBox(event) {
  const sellingChannelCell = document.querySelector('.selling-channel-cell.active');
  if (!sellingChannelCell) return;
  if (!event.composedPath().find(el => isInsidesellingChannelCell(el, sellingChannelCell))) {
    sellingChannelCell.controller.hidePopBox();
  };
}

function isInsidesellingChannelCell(el) {
  if (el.classList?.contains('selling-channel-cell')) return true;
  return false;
}

import { ApplicationController } from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['popBox', 'modal']
  static values = {
    productId: Number,
    sellingChannelId: Number,
    path: String,
    active: Boolean
  }

  connect() {
    this.element.controller = this;
  }

  get otherProductSellingChannelControllers() {
    return Array.from(document.querySelectorAll(`[data-controller="${this.identifier}"]`)).map(el => el.controller);
  }

  showPopBox(event) {
    if (this.activeValue) {
      if (event.composedPath().filter(el => el.dataset).find(el => el.dataset.popboxNoclose !== undefined)) return;
      this.hidePopBox();
      return;
    };
    
    window.removeEventListener('click', hidePopBox)
    this.otherProductSellingChannelControllers.forEach(el => el.hidePopBox());
    fetch(this.pathValue, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        this.popBoxTarget.innerHTML = data.html;
        // 👇 sinon la modal s'ouvre mal et n'est pas visible
        if (this.hasModalTarget) {
          document.body.append(this.modalTarget);
        }
      })
    this.element.classList.add('active');
    this.activeValue = true;
    setTimeout(() => window.addEventListener('click', hidePopBox), 1);
  }

  hidePopBox() {
    this.element.classList.remove('active')
    this.activeValue = false;
  }

  updateCategories(event) {
    event.preventDefault();
    const form = event.currentTarget;
    this.fetchWithToken(form.action, {
      method: 'POST',
      headers: { 'Accept': 'application/json' },
      body: new FormData(form)
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      form.outerHTML = data.form_html;
      if (data.success) this.hidePopBox();
      // TO DO => afficher un messages à l'utilisateur
    })
  }
}