import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    document.body.insertAdjacentHTML('beforeend', this.#btnHtml());
    window.addEventListener('scroll', e => this.#scroll(e));
    if (this.#btn) {
      this.#btn.addEventListener('click', e => window.scrollTo(0,0));
    }
  }

  get #btn() {
    return document.getElementById('scroll-top-btn');
  }

  #scroll(event) {
    if (!this.#btn) return;
    if (window.scrollY > 0) {
      this.#btn.classList.remove('d-none')
    } else {
      this.#btn.classList.add('d-none')
    };
  }

  scrollTop() {
    console.log('scroll top');
    window.scrollTo(0,0);
  }

  #btnHtml() {
    return `
      <div id="scroll-top-btn" class="d-none rounded-full w-8 h-8 bg-black text-white fixed bottom-8 right-8 flex items-center justify-content-center cursor-pointer">
        <i class="fa-solid fa-arrows-up-to-line"></i>
      </div>
    `
  }
}
