import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "body" ]

  show() {
    $(this.element).modal('show');
  }

  refreshBody(html) {
    this.bodyTarget.innerHTML = html;
  }

  connect() {
    this.element["controller"] = this;
  }
}
