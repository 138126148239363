import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "trigger", "dropdown" ]

  connect() {
    document.addEventListener('click', this.closeOnOutsideClick.bind(this))
    // document.addEventListener('click', (event) => { this.closeOnOutsideClick(event) })
  }

  disconnect() {
    document.removeEventListener('click', this.closeOnOutsideClick.bind(this))
  }

  closeOnOutsideClick(event) {
    if (this.isClosed) return;
    if (this.dropdownTarget.contains(event.target)) return;
    if (this.triggerTarget == event.target) return;
    this.#close();
  }

  get isClosed() {
    return this.dropdownTarget.classList.contains('d-none')
  }

  get isOpen() {
    return !this.isOpen;
  }

  toggle(event) {
    this.dropdownTarget.classList.toggle('d-none');
  }

  #close() {
    this.dropdownTarget.classList.add('d-none');
  }
}
