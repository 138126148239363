import { Controller } from "stimulus"

export default class extends Controller {
  // All cells in table must have a data-row attribute
  // All cells in common row must have the same data-row value
  // => this is how the height of cells in the same row is calculated
  static targets = [ 
    "tableContainer",
    "tableLeft",
    "tableRight",
    "selectColumnsBox",
    "columnCheckBox"
  ]

  static values = {
    filteredSellingChannelPrettyName: String
  }

  connect() {
    this.#setTableRightWidth();
    this.#bindScrollsOnRowRightarget();
    this.#setCellsHeight();
    this.#fillSelectColumnsBox();
    setTimeout(() => {
      this.#showOnlyFilteredSellingChannelColumn();
    }, 10);
  }

  toggleSelectColumnBox(event) {
    if (!this.hasSelectColumnsBoxTarget) return;

    this.selectColumnsBoxTarget.classList.toggle('d-none');
  }

  toggleColumn(event) {
    const cells = this.element.querySelectorAll(`[data-column="${event.currentTarget.value}"]`);
    if (cells.length === 0) return;

    if (event.currentTarget.checked) {
      cells.forEach(cell => cell.classList.remove('d-none'));
    } else {
      cells.forEach(cell => cell.classList.add('d-none'));
    }
  }

  toggleAllColumns(event) {
    this.columnCheckBoxTargets.forEach(target => target.click());
  }

  #setTableRightWidth() {
    this.tableRightTarget.style.width = `${this.tableContainerTarget.offsetWidth - this.tableLeftTarget.offsetWidth}px`;
  }

  #bindScrollsOnRowRightarget() {
    this.tableRightTargets.forEach(target => {
      target.addEventListener("scroll", (e) => this.#setTableRightTargetsScrollPosition(e.currentTarget.scrollLeft));
    });
  }

  #setTableRightTargetsScrollPosition(scrollLeft) {
    this.tableRightTargets.forEach(target => target.scrollLeft = scrollLeft);
  }

  #setCellsHeight() {
    const rows = this.element.querySelectorAll('[data-row]');
    if (rows.length === 0) return;

    const rowsValues = [...new Set(Array.from(rows).map(el => el.dataset.row ))]
    rowsValues.forEach(cellValue => {
      const rowsWithSameValue = this.element.querySelectorAll(`[data-row="${cellValue}"]`);
      const maxHeight = Math.max(...Array.from(rowsWithSameValue).map(el => el.offsetHeight));
      rowsWithSameValue.forEach(cellInRow => cellInRow.style.height = `${maxHeight}px`);
    });
  }

  get columnNames() {
    const columns = this.element.querySelectorAll('[data-column]');
    if (columns.length === 0) return [];

    return [...new Set(Array.from(columns).map(el => el.dataset.column ))]
  }

  #fillSelectColumnsBox() {
    const html = this.columnNames.map(columnName => {
      return `
        <div class="align-items-center flex gap-1.5 text-gray-600 text-sm mb-2">
          <input ${columnName === 'archived' ? '' : 'checked'} class="h-3" type="checkbox" id="${columnName}" name="${columnName}" value="${columnName}" data-action="input->${this.identifier}#toggleColumn" data-${this.identifier}-target="columnCheckBox">
          <label class="mb-0 flex-grow-1" for="${columnName}">${columnName}</label>
        </div>
      `
    }).join('');
    const allInput = `
      <div class="align-items-center border-b flex gap-1.5 mb-2 pb-2 text-gray-800 text-sm">
        <input checked class="h-3" type="checkbox" id="all" name="all" value="all" data-action="input->${this.identifier}#toggleAllColumns" data-${this.identifier}-target="columnCheckBox">
        <label class="mb-0 flex-grow-1" for="all">Toutes les colonnes</label>
      </div>
    `
    this.selectColumnsBoxTarget.innerHTML = `${allInput}${html}`;
  }

  #showOnlyFilteredSellingChannelColumn() {
    if (this.filteredSellingChannelPrettyNameValue === '') return;

    this.columnCheckBoxTargets.find(target => target.id === "all" )?.click();
    setTimeout(() => {
      this.columnCheckBoxTargets.find(target => target.id === "discontinued")?.click();
      this.columnCheckBoxTargets.find(target => target.id === "archived")?.click();
      this.columnCheckBoxTargets.find(target => target.id === "stock")?.click();
      this.columnCheckBoxTargets.find(target => target.id === this.filteredSellingChannelPrettyNameValue)?.click();
    }, 200);
  }
}
