const stickyCell = () => {
  const stickyCells = document.querySelectorAll('.sticky-cell');
  const productsTable = document.querySelector('.c-products');
  if (!productsTable) return;
  const productsTableOffset = productsTable.offsetLeft;
  stickyCells.forEach(cell => {
    const cellOffset = cell.offsetLeft - productsTableOffset;
    cell.style.left = `${cellOffset}px`;
  })
}

export { stickyCell };
