import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "showWebsitesBtn", "website", "showMarketplacesBtn", "marketplace",
    "showAnkorstoreBrandsBtn", "ankorstoreBrand", "showFaireBrandsBtn", "faireBrand" ]

  connect() {
    this.websitesAreHidden = true;
    this.marketplacesAreHidden = true;
    this.ankorstoreBrandsAreHidden = true;
    this.faireBrandsAreHidden = true;
  }

  showWebsites() {
    this.websitesAreHidden = !this.websitesAreHidden;
    this.show(this.showWebsitesBtnTarget, this.websiteTargets, this.websitesAreHidden);
  }

  showMarketplaces() {
    this.marketplacesAreHidden = !this.marketplacesAreHidden;
    this.show(this.showMarketplacesBtnTarget, this.marketplaceTargets, this.marketplacesAreHidden);
  }

  showAnkorstoreBrands() {
    this.ankorstoreBrandsAreHidden = !this.ankorstoreBrandsAreHidden;
    this.show(this.showAnkorstoreBrandsBtnTarget, this.ankorstoreBrandTargets, this.ankorstoreBrandsAreHidden);
  }

  showFaireBrands() {
    this.faireBrandsAreHidden = !this.faireBrandsAreHidden
    this.show(this.showFaireBrandsBtnTarget, this.faireBrandTargets, this.faireBrandsAreHidden);
  }

  show(btn, targets, variable) {
    event.preventDefault();
    targets.forEach((target) => {
      if (variable) {
        target.classList.add('d-none')
      } else {
        target.classList.remove('d-none')
      }
    });
    if (variable) {
      const regex = /Hide/i;
      btn.innerText = btn.innerText.replace(regex, 'Show');
    } else {
      const regex = /Show/i;
      btn.innerText = btn.innerText.replace(regex, 'Hide');
    }
  }
}
