import { Controller } from "stimulus"
import { csrfToken } from '@rails/ujs';

export class ApplicationController extends Controller {
  static values = {
    orderId: Number
  }

  get rightSideOverlayController() {
    const overlay = document.querySelector('#right-side-overlay');
    if (overlay) return overlay['shared--right-side-overlay'];
  }

  get orderCurrentStatusControllers() {
    if (!this.hasOrderIdValue) return;

    const elements = Array.from(document.querySelectorAll('[data-controller="shared--current-order-status"]'))
    if (elements.length === 0) return [];

    const orderStatuses = elements.filter(el => el.dataset.orderId == this.orderIdValue);
    if (orderStatuses.length === 0) return [];

    return orderStatuses.map(el => el["shared--current-order-status"])
  }

  fetchWithToken(url, options) {
    options.headers = {
      'X-CSRF-Token': csrfToken(),
      ...options.headers,
    };
    options.credentials = 'same-origin';

    return fetch(url, options);
  };
}
