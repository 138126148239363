import { Controller } from "stimulus"
import { fetchWithToken } from '../utils/fetch_with_token';
import swal from 'sweetalert';

export default class extends Controller {
  static targets = [ "processBtn", "productsBtn" ]
  static values = {
    path: String
  }

  process() {
    this.processBtnTarget.innerHTML = `
      <div class="spinner-border spinner-border-sm mr-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      Import en cours...
    `
    fetchWithToken(this.pathValue, {
      method: "post",
      headers: { "Accept": "application/json" }
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        if (data.bg_job) {
          swal({
            title: data.message,
            text: "Ça ne change pas grand chose pour vous 🙂 Vous serez prévenu dès que l'import sera terminé !",
            icon: "info",
          })
        } else {
          this.productsBtnTarget.click();
        }
      } else {
        const message = `L'import du fichier n'a pas pu être exécuté. Le message d'erreur remonté est : ${data.message}. (si le message n'aide pas à résoudre le problème, n'hésitez pas à contacter le développeur du site)`;
        swal({
          title: "Une erreur est survenue!",
          text: message,
          icon: "error",
        })
        .then(value => {
          this.processBtnTarget.innerHTML = "Importer";
        })
      }
    })
  }


  submit() {
    event.preventDefault();
    swal({
      title: "Une dernière petite confirmation 🙏",
      text: "Juste pour être sûr que vous n'avez pas appuyer sur le bouton par mégarde.",
      icon: "warning",
      buttons: true,
    })
    .then(willProcess => {
      if (willProcess) {
        this.process();
      } else {
        swal("On a bien fait de poser la question on dirait ! 😬")
      }
    })
  }
}
