import { ApplicationController } from "../application_controller";
import swal from 'sweetalert';

export default class extends ApplicationController {
  static values = {
    websiteName: String,
    brandName: String,
    targetUrl: String
  }

  get #selectedProductIds() {
    return localStorage.getItem('product_ids') ? JSON.parse(localStorage.getItem('product_ids')) : [];
  }

  connect() {
    // this.location = window.location.pathname;
  }

  submit(event) {
    event.preventDefault();
    event.stopPropagation();
    
    if (this.element.tagName == "FORM") {
      var formData = new FormData(this.element);
      formData.append('website', this.websiteNameValue);
      this.#selectedProductIds.forEach(id => formData.append('product_ids[]', id));
    } else {
      var bodyData = JSON.stringify({ 
        brand: this.brandNameValue,
        product_ids: this.#selectedProductIds
      })
    }
    const payload = this.element.tagName == "FORM" ? formData : bodyData;

    var headers = { "Accept": "application/json" };
    if (typeof(payload) == "string") {
      headers["Content-Type"] = "application/json";
    }

    this.fetchWithToken(this.targetUrlValue, {
      method: 'POST',
      headers: headers,
      body: payload
    })
    .then(response => response.json())
    .then(data => {
      swal({
        title: data.message,
        icon: "info",
        text: data.text
      })
      .then(() => {
        if (this.element.tagName == "FORM") {
          window.location.reload();
        } else {
          // console.log('heyyy there!!')
          // const btn = document.createElement('a')
          // btn.id = 'js-reload-btn';
          // btn.setAttribute('href', this.location)
          // btn.classList.add('d-none');
          // document.body.append(btn);
          // const btnElement = document.getElementById('js-reload-btn')
          // if (btnElement) btnElement.click();
        }
      })
    })
  }
}