import { ApplicationController } from "../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['emptyStateContent', 'fullStateContent', 'fullStateMessage']

  connect() {
    this.element[this.identifier] = this;
  }
  
  updateMessage(message) {
    this.fullStateMessageTarget.innerText = message;
  }

  showEmptyState() {
    this.emptyStateContentTarget.classList.remove('hidden');
    this.fullStateContentTarget.classList.add('hidden');
  }
}