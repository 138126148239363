import { Controller } from "stimulus"
import { fetchWithToken } from '../utils/fetch_with_token';

export default class extends Controller {
  static targets = [ "stockContainer", "btn" ]
  static values = {
    productIds: Array
  }

  call() {
    event.preventDefault();
    this.btnTarget.innerHTML = `
      Afficher les stocks disponibles
      <div class="spinner-grow spinner-grow-sm text-dark ml-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    `
    fetchWithToken(`/products/fetch_rf_stocks`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ product_ids: this.productIdsValue })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      Object.keys(data.stocks).forEach((sku) => {
        const target = this.stockContainerTargets.find(t => t.dataset.sku === sku);
        target.innerText = ` - Stock RF : ${data.stocks[sku]}`
        target.classList.add(`text-${data.stocks[sku] < parseInt(target.dataset.quantity) ? 'red' : 'green'}-500`);
        this.btnTarget.innerHTML = `Afficher les stocks disponibles`;
      })
    })
  }
}
