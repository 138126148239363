import { ApplicationController } from "../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['line']
  static values = {
    recipient: String,
  }

  connect() {
    this.element.controller = this;
  }

  refreshLine(html) {
    this.lineTarget.innerHTML = html;
  }
}