import { Controller } from "stimulus";
import SlimSelect from 'slim-select';

export default class extends Controller {
  static targets = [ "popover", "input" ]
  static values = {
    fieldId: Number,
  }

  get fieldContentsFiltersContainer() {
    return document.querySelector('.js-field-content-filters-container');
  }

  get filtersFormInput() {
    // const element = this.hasInputTarget ? this.inputTarget : this.selectTarget;
    // return this.fieldContentsFiltersContainer.querySelector(`[name='${element.getAttribute("name")}']`);
    return this.fieldContentsFiltersContainer.querySelector(`[name='filters[field[${this.fieldIdValue}]]']`);
  }

  get filtersFormSubmit() {
    return document.querySelector('.js-filters-form-submit');
  }

  showPopover() {
    const popovers = document.querySelectorAll("[class^='rg-popo-']")
    popovers.forEach(popover => {
      if (popover != this.popoverTarget) popover.classList.add('d-none');
    });
    this.popoverTarget.classList.toggle('d-none');
    if (this.hasInputTarget) {
      this.inputTarget.focus();
      this.inputTarget.select();
    }
  }

  removeFiltersFormInput() {
    if (this.filtersFormInput) this.filtersFormInput.remove();
  }

  submit() {
    if (event.type == "keyup" && event.key != "Enter") return;
    this.removeFiltersFormInput();
    const input = this.hasInputTarget ? this.inputTarget.cloneNode(true) : this.inputForBoolean();
    this.fieldContentsFiltersContainer.appendChild(input);
    this.filtersFormSubmit.click();
  }

  reset() {
    event.preventDefault();
    if (this.hasInputTarget) this.inputTarget.value = '';
    this.removeFiltersFormInput();
    this.filtersFormSubmit.click();
  }

  checkedFilter() {
    this.setValueAndSubmit("true");
  }

  uncheckedFilter() {
    this.setValueAndSubmit("false");
  }

  withValueFilter() {
    this.setValueAndSubmit("with_value");
  }

  withoutValueFilter() {
    this.setValueAndSubmit("empty_value");
  }

  setValueAndSubmit(value) {
    event.preventDefault();
    if (this.hasInputTarget) this.inputTarget.value = '';
    const input = this.hasInputTarget ? this.inputTarget.cloneNode(true) : this.inputForBoolean();
    input.value = value;
    this.removeFiltersFormInput();
    this.fieldContentsFiltersContainer.appendChild(input);
    this.filtersFormSubmit.click();
  }

  inputForBoolean() {
    const input = document.createElement('input');
    input.setAttribute('name', `filters[field[${this.fieldIdValue}]]`);
    // input.value = this.selectTarget.value;
    return input;
  }
}
