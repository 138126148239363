import { Controller } from "stimulus"
import { fetchWithToken } from '../utils/fetch_with_token';

export default class extends Controller {
  static targets = [ "form", "matchInput", "createInput", "matchBtn", "createBtn" ]
  static values = { path: String }

  get matchFormIsSubmitable() {
    return this.matchInputTarget.value != "";
  }

  get createFormIsSubmitable() {
    const emptyInputs = this.createInputTargets.filter(input => input.value === "");
    return emptyInputs.length === 0;
  }

  get nextStepBtn() {
    return document.getElementById('next_step');
  }

  get allFieldsAreReady() {
    const icons = Array.from(document.querySelectorAll('.c-unkown-field__icon .fa-question'));
    return icons.every(icon => window.getComputedStyle(icon, null).display === "none");
  }

  connect() {
    this.formTargets.forEach(form => form.addEventListener('submit', e => this.submit(e)));
    this.allFieldsAreReady ? this.showBtn(this.nextStepBtn) : this.hideBtn(this.nextStepBtn);
  }

  submit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.classList.contains("c-unkown-field__match_form")) {
      if (!this.matchFormIsSubmitable) return;
    } else {
      if (!this.createFormIsSubmitable) return;
    }
    const formData = new FormData(form);
    fetchWithToken(this.pathValue, {
      method: 'PATCH',
      body: formData
    })
    .then(response => response.text())
    .then(html => {
      this.element.innerHTML = html;
      this.connect();
      this.allFieldsAreReady ? this.showBtn(this.nextStepBtn) : this.hideBtn(this.nextStepBtn);
    })
  }

  showMatchBtn() {
    const btn = this.matchBtnTarget;
    this.matchFormIsSubmitable ? this.showBtn(btn) : this.hideBtn(btn);
  }

  showCreateBtn() {
    const btn = this.createBtnTarget;
    this.createFormIsSubmitable ? this.showBtn(btn) : this.hideBtn(btn);
  }

  showBtn(btn) {
    btn.classList.remove('d-none');
  }

  hideBtn(btn) {
    btn.classList.add('d-none');
  }
}
