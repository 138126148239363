import { ApplicationController } from "../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['showBtn', 'hideBtn', 'dataContainer']

  connect() {
    // this.element[this.identifier] = this;
  }
  
  show() {
    this.showBtnTarget.classList.add('hidden');
    this.hideBtnTarget.classList.remove('hidden');
    this.dataContainerTarget.classList.remove('hidden');
  }
  
  hide() {
    this.showBtnTarget.classList.remove('hidden');
    this.hideBtnTarget.classList.add('hidden');
    this.dataContainerTarget.classList.add('hidden');
  }
}