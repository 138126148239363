import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "filtersForm", "fieldsSelect", 'submitBtn' ]

  get filtersFormInputs() {
    return this.filtersFormTarget.querySelectorAll('input');
  }

  get filtersFormSelects() {
    const selects = this.filtersFormTarget.querySelectorAll('select');
    return Array.from(selects).filter(select => !this.fieldsSelectTargets.includes(select));
  }

  get fieldContentsFiltersContainer() {
    return this.filtersFormTarget.querySelector('.js-field-content-filters-container');
  }

  resetFilters() {
    event.preventDefault();
    this.filtersFormInputs.forEach(input => input.value = "");
    this.filtersFormSelects.forEach(select => {
      Array.from(select.options).forEach(option => option.selected = false)
    })
    this.fieldContentsFiltersContainer.innerHTML = "";
    this.submitBtnTarget.click();
  }

  connect() {
    this.element['controller'] = this;
  }
}
