import { ApplicationController } from "../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['label', 'textarea', 'submitBtn']
  static values = {
    refresh: Boolean,
    scope: String
  }
  // static outlets = ['productsShowHeader', 'hello']

  get headerOutlet() {
    const header = document.querySelector('[data-controller="products--show-header"]');
    if (!header) return;

    return header.controller;
  }
  
  connect() {
    this.element.controller = this;
    this.#addResizeBehaviourToTextarea();
    this.#addScopeToLabel();
    if (this.refreshValue) {
      this.element.classList.add('bg-green-50')
      setTimeout(() => {
        this.element.classList.remove('bg-green-50')
      }, 500)
    }
  }
  
  #addResizeBehaviourToTextarea() {
    if (!this.hasTextareaTarget) return;

    this.textareaTarget.style.boxSizing = 'border-box';
    var offset = this.textareaTarget.offsetHeight - this.textareaTarget.clientHeight;
    this.textareaTarget.style.height = "36px";
    this.textareaTarget.style.height = this.textareaTarget.scrollHeight + offset + "px";
    this.textareaTarget.addEventListener('input', function (event) {
      event.target.style.height = "36px";
      event.target.style.height = event.target.scrollHeight + offset + 'px';
    });
  }

  #addScopeToLabel() {
    if (!this.hasLabelTarget) return;
    if (this.scopeValue.length == 0) return;

    this.labelTarget.insertAdjacentHTML('beforeend', `<span class="text-white text-xs ml-1 font-semibold bg-gray-300 rounded-full px-1">${this.scopeValue}</span>`)
  }

  showBtn(event) {
    this.element.classList.add('bg-blue-50');
    this.submitBtnTarget.style.opacity = 1;
  }
  
  submit(event) {
    event.preventDefault();
    const submitBtnInnerHTML = this.submitBtnTarget.innerHTML;
    this.submitBtnTarget.innerHTML = `
    <div class="spinner-grow spinner-grow-sm text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    `
    this.fetchWithToken(this.element.action, {
      method: this.element.method,
      headers: { 
        "Accept": "application/json"
      },
      body: new FormData(this.element)
    })
    .then(response => response.json())
    .then(data => {
      if (this.headerOutlet && data.data_for_header) this.headerOutlet.refresh(data.data_for_header);
      this.element.outerHTML = data.edit_html;
      this.#addResizeBehaviourToTextarea();
      if (data.success && this.headerOutlet) this.headerOutlet.refresh(data.data_for_header);
      // 👇 s'il y a un pb de validation sur l'instance
      if (!data.success) {
        this.element.classList.remove('bg-blue-50');
        this.element.classList.add('bg-red-50');
        this.submitBtnTarget.style.opacity = 1;
        this.element.querySelector('label').classList.add('text-red-500');
        this.element.querySelector('.form-control').classList.add('text-red-500');
      }
    })
  }
}