import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["stickyPageHeaderElement", "stickyTableHeaderElement", "scrollableElement"]

  connect() {
    this.#setStickyTableHeaderTop();
    window.addEventListener('resize', e => this.#setStickyTableHeaderTop());
  }

  get #tableHeaderScrollableElement() {
    return this.stickyTableHeaderElementTarget.querySelector('.no-scrollbar');
  }

  scroll(event) {
    const otherScrollableElements = this.scrollableElementTargets.filter(element => element !== event.currentTarget);
    otherScrollableElements.forEach((element) => {
      // if (this.#tableHeaderScrollableElement && element == this.#tableHeaderScrollableElement) {
      //   event.currentTarget.scrollLeft > 0 ? element.classList.add('overflow-x-scroll') : element.classList.remove('overflow-x-scroll');
      // }
      element.scrollLeft = event.currentTarget.scrollLeft
    });
  }

  #setStickyTableHeaderTop() {
    if (this.hasStickyTableHeaderElementTarget) {
      this.stickyTableHeaderElementTarget.style.top = `${this.stickyPageHeaderElementTarget.offsetHeight}px`;
    } else {
      this.stickyTableHeaderElementTarget.style.top = `0px`;
    }
  }

  disconnect() {
    window.removeEventListener('resize', e => this.#setStickyTableHeaderTop());
  }
}
