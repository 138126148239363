import { Controller } from "stimulus";
import { fetchWithToken } from '../../utils/fetch_with_token';

export default class extends Controller {
  static targets = [ "columnsChoice", "productsChoice", "fieldsIds",
    "productsIds", "submit", "form" ]

  get filtersForm() {
    return document.querySelector('form.js-filters-form');
  }

  get columnsChoice() {
    return this.columnsChoiceTargets.find(input => input.checked).value;
  }

  get productsChoice() {
    return this.productsChoiceTargets.find(input => input.checked).value;
  }

  get nonProductsChoice() {
    return this.productsChoiceTargets.find(input => input.value == 'none');
  }

  get filteredProductsIds() {
    return document.querySelector('[data-products-ids]').dataset.productsIds;
  }

  get selectedProductsIds() {
    return sessionStorage.getItem('products_ids');
  }

  get selectedFieldsIds() {
    const fieldsSelect = document.getElementById(`main_filters_form_filters_fields`);
    const fieldsSelectOptions = Array.from(fieldsSelect);
    return JSON.stringify(fieldsSelectOptions.filter(opt => opt.selected).map(opt => opt.value));
  }

  connect() {
    this.element['controller'] = this;
  }

  changeDataScope(event) {
    if (event.currentTarget.value != 'images') {
      this.nonProductsChoice.closest('span.radio').classList.remove('d-none');
    } else {
      this.nonProductsChoice.closest('span.radio').classList.add('d-none');
      if (this.productsChoice == 'none') this.productsChoiceTargets[0].click();
    };
  }

  submit(event) {
    event.preventDefault();
    this.formTarget.reset();
    this.submitTarget.removeAttribute('disabled');
    switch(this.columnsChoice) {
      case 'selected': { this.fieldsIdsTarget.value = this.selectedFieldsIds; break }
      default: { this.fieldsIdsTarget.value = this.columnsChoice }
    }
    console.log(this.fieldsIdsTarget.value);
    switch(this.productsChoice) {
      case 'filtered': { this.productsIdsTarget.value = this.filteredProductsIds; break };
      case 'selected': { this.productsIdsTarget.value = this.selectedProductsIds; break };
      default: { this.productsIdsTarget.value = this.productsChoice }
    }
    this.submitTarget.click();
    document.querySelector('#export_modal .close').click();
  }
}
