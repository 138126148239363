const fieldModal = () => {
  createFieldModal()  ;
  updateFieldModal()  ;
}

const updateFieldModal = () => {
  const modal = document.getElementById("update_field_modal");
  if (!modal) return;

  $('#update_field_modal').on('show.bs.modal', (event)  => {
    const title = modal.querySelector('h5');
    title.innerText = event.relatedTarget.dataset.title;

    const form = modal.querySelector('form');
    form.setAttribute('action', event.relatedTarget.dataset.formAction)

    const categorySelect = modal.querySelector("#field_field_category_id");
    categorySelect.value = event.relatedTarget.dataset.categoryId;

    const nameInput = modal.querySelector("#field_name");
    nameInput.classList.remove('is-invalid');
    nameInput.value = event.relatedTarget.dataset.name;

    const dataTypeSelect = modal.querySelector("#field_data_type");
    dataTypeSelect.value = event.relatedTarget.dataset.dataType;

    const recipientCheckboxes = modal.querySelectorAll('[name="field[recipient_ids][]"]');
    const recipientIds = JSON.parse(event.relatedTarget.dataset.recipientIds);

    recipientCheckboxes.forEach(checkbox => {
      checkbox.checked = false
      const checkboxRecipientId = parseInt(checkbox.getAttribute("value"));
      if (recipientIds.includes(checkboxRecipientId)) checkbox.checked = true;
    });
  })

  $('#update_field_modal').on('shown.bs.modal', () => { addFocusOnNameInput(modal) });
}

const createFieldModal = () => {
  const modal = document.getElementById("new_field_modal");
  if (!modal) return;

  $('#new_field_modal').on('show.bs.modal', (event)  => {
    const categorySelect = modal.querySelector("#field_field_category_id");
    categorySelect.value = event.relatedTarget.dataset.categoryId;
  })

  $('#new_field_modal').on('shown.bs.modal', () => { addFocusOnNameInput(modal) });
}

const addFocusOnNameInput = (modal) => {
  const fieldNameInput = modal.querySelector("#field_name");
  // le focus ne fonctionne pas sans le setTimeOut...
  setTimeout(function() { fieldNameInput.focus() }, 10);
}

export { fieldModal };
