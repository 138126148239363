import { ApplicationController } from "./application_controller";
import flatpickr from "flatpickr";

export default class extends ApplicationController {
  static targets = [ "overlayContent" ]

  get overlayElement() {
    return document.querySelector('#right-side-overlay')
  }

  open() {
    event.preventDefault();
    const overlayContent = this.overlayContentTarget.cloneNode(true);
    overlayContent.classList.remove('hidden');

    // 👇 pour éviter que les labels soient connectés au form original
    overlayContent.querySelectorAll('label').forEach(label => {
      label.setAttribute('for', label.getAttribute('for') + '_2')
    });
    overlayContent.querySelectorAll('input').forEach(input => {
      input.id = input.id + '_2';
    });

    this.rightSideOverlayController.refreshBody(overlayContent);
    this.rightSideOverlayController.show();
    flatpickr(document.querySelector('#right-side-overlay .date-range-picker'), {
      mode: "range",
      appendTo: this.overlayElement.querySelector('.filters_dates'),
    });
  }
}
