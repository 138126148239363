import { Controller } from "stimulus"
import { fetchWithToken } from '../../utils/fetch_with_token';
import swal from 'sweetalert';
export default class extends Controller {
  static targets = [ "checkBox" ]

  connect() {}

  toggle(event) {
    event.preventDefault();
    const target = event.currentTarget;
    const dataSet = { ...target.dataset }
    const productData = (({ productId, sellingChannelProductId }) => ({ productId, sellingChannelProductId }))(dataSet);
    const sellingChannelId = dataSet.sellingChannelId;
    const urlBase = `/selling_channels/${sellingChannelId}/selling_channel_products/`

    if (!this.#productIsSelected(productData.productId)) {
      this.#toggleProduct(productData, urlBase, target);
      return;
    }
    
    swal({
      title: "Appliquer la modification à toute la sélection ?",
      text: `Vous avez sélectionnez plusieurs produits (cf. 1ère colonne du tableau). Souhaitez-vous appliquer la modification à toute la sélection ?`,
      icon: "warning",
      buttons: {
        apply_to_selection: {
          text: "oui, merci.",
          value: "apply_to_selection",
          className: "btn btn-success",
        },
        only_clicked: {
          text: "Non, uniquement au produit modifié.",
          value: "only_clicked",
          className: "btn btn-primary",
        },
      },
      dangerMode: true,
    })
    .then((value) => {
      switch (value) {
        case "apply_to_selection":
          this.#toggleSelection(productData, urlBase);
          break;
        case "only_clicked":
          this.#toggleProduct(productData, urlBase, target);
          break;
        default:
          swal("C'est bon, on n'a touché à rien.😶");
      }
    });
  }

  #toggleSelection(productData, urlBase) {
    const actionType = productData.sellingChannelProductId === '' ? 'toggle-on' : 'toggle-off';
    const data = {
      product_ids: this.#selectedProductsIds,
      action_type: actionType
    }
    fetchWithToken(`${urlBase}toggle_selection`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then((data) => {
        console.log(data)
        console.log(data.product_ids_with_scp_ids)
        if (data.success) {
          this.#selectedProductCheckboxTargets.forEach((cB) => {
            cB.innerHTML = this.#checkBoxHTML(productData.sellingChannelProductId === '')
            if (data.product_ids_with_scp_ids) {
              cB.dataset.sellingChannelProductId = data.product_ids_with_scp_ids[cB.dataset.productId]
            } else  {
              cB.dataset.sellingChannelProductId = ''
            }
          })
        }
      })
  }

  #toggleProduct(productData, urlBase, target) {
    const data = {
      product_id: productData.productId,
      selling_channel_product_id: productData.sellingChannelProductId,
    }
    fetchWithToken(`${urlBase}toggle`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then((data) => {
        if (data.success) {
          target.innerHTML = this.#checkBoxHTML(data.scp_id != '')
          target.dataset.sellingChannelProductId = data.scp_id;
        }
      })
  }

  get #selectedProductsIds() {
    return localStorage.getItem('product_ids') ? JSON.parse(localStorage.getItem('product_ids')) : [];
  }

  get #selectedProductCheckboxTargets() {
    return this.checkBoxTargets.filter(cB => this.#selectedProductsIds.includes(parseInt(cB.dataset.productId)));
  }

  #productIsSelected(productId) {
    return this.#selectedProductsIds.includes(parseInt(productId))
  }

  #checkBoxHTML(checked = true) {
    return `<i class="far fa${checked ? '-check' : ''}-square"></i>`
  }
}
