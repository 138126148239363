import { ApplicationController } from "../../../../javascript/controllers/application_controller";
import swal from 'sweetalert';

export default class extends ApplicationController {
  static values = {
    path: String,
    from: String
  }

  get waitingForShipmentOrdersSectionController() {
    const ordersSections = Array.from(document.querySelectorAll('[data-controller="dashboard--orders-section"]'))
    if (ordersSections.length === 0) return;

    const ordersSection = ordersSections.find(section => section.id === "b2c_waiting_for_shipment");
    if (!ordersSection) return;

    return ordersSection['dashboard--orders-section']
  }

  get waitingForShipmentIntegrationOrdersSectionController() {
    const ordersSections = Array.from(document.querySelectorAll('[data-controller="dashboard--orders-section"]'))
    if (ordersSections.length === 0) return;

    const ordersSection = ordersSections.find(section => section.id === "b2c_waiting_for_shipment_integration");
    if (!ordersSection) return;

    return ordersSection['dashboard--orders-section']
  }

  call() {
    event.preventDefault();
    swal({
      title: `Cette action n'a aucun impact sur ${this.fromValue}${this.fromValue === 'Lengow' ?  ' ou la marketplace' : ''}`,
      text: "En confirmant la prise en compte de l'expédition, vous ferez simplement évoluer le status dans la commande dans XYZ. Aucune information n'est transmise à la plateforme de vente. La commande n'apparaitra plus dans le centre de contrôle des commandes sur XYZ. Veuillez donc confirmer uniquement si la plateforme de vente a bien intégré l'expédition.",
      icon: "info",
      buttons: {
        confirm: {
          text: "L'expédition a bien été prise en compte",
          value: "confirm",
          className: "btn btn-success",
        },
        test: {
          text: "Annuler",
          value: "test",
          className: "btn btn-secondary",
        },
      },
      dangerMode: true,
    })
    .then((value) => {
      switch (value) {
        case "confirm":
          this.confirmShipment();
          break;
        case "test":
          break;
      }
    })
  }

  confirmShipment() {
    event.preventDefault();
    this.fetchWithToken(this.pathValue, {
      method: 'POST',
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then(data => {
      this.orderCurrentStatusControllers.forEach(el => el.refresh(data.current_status_html));
      this.rightSideOverlayController.refreshBody(data.order_show_html);
      if (data.waiting_for_shipment_orders_section_html) this.waitingForShipmentOrdersSectionController.refresh(data.waiting_for_shipment_orders_section_html);
      if (data.waiting_for_shipment_integration_orders_section_html) this.waitingForShipmentIntegrationOrdersSectionController.refresh(data.waiting_for_shipment_integration_orders_section_html);
    })
  }
}
