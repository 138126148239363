import { Controller } from "stimulus"
import { fetchWithToken } from '../utils/fetch_with_token';

export default class extends Controller {
  static targets = [ "form", "label", "input", "dialog", "submit", "nextStepBtn" ]

  get fileName() {
    return this.inputTarget.files[0] ? this.inputTarget.files[0].name : undefined;
  }

  get fileIsCsv() {
    if (!this.fileName) return;
    return this.fileName.match(/\..*$/)[0] === ".csv";
  }

  get dialogValidContent() {
    if (!this.fileName) return;
    return `
      <span class="mb-2">${this.fileName}</span>
      <i class="far fa-check-circle"></i>
    `
  }

  dialogInvalidContent(message) {
    if (!this.fileName) return;
    return `
      <span class="mb-2">${this.fileName}</span>
      <i class="far fa-times-circle"></i>
      <span>${message}</span>
      <span>Cliquer ici pour choisir un nouveau fichier</span>
    `
  }

  get dialogSpinner() {
    return `
      <div class="spinner-border mb-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <span>Analyse du fichier en cours...</span>
    `
  }

  invalidForm(message = "Seuls les fichiers .csv sont acceptés") {
    this.dialogTarget.classList.remove('invalid');
    this.dialogTarget.classList.remove('valid');

    this.dialogTarget.innerHTML = this.dialogInvalidContent(message);
    this.dialogTarget.classList.add('invalid');
    this.submitTarget.classList.add('d-none');
  }

  validForm() {
    this.dialogTarget.classList.remove('invalid');
    this.dialogTarget.classList.remove('valid');

    this.dialogTarget.innerHTML = this.dialogValidContent;
    this.dialogTarget.classList.add('valid');
    this.submitTarget.classList.remove('d-none');
    this.submitTarget.removeAttribute('disabled');
  }

  change() {
    this.fileIsCsv ? this.validForm() : this.invalidForm();
  }

  choseFile() {
    this.labelTarget.click();
  }

  submit() {
    event.preventDefault();
    if (!this.fileName) return;
    if (!this.fileIsCsv) return;
    this.dialogTarget.innerHTML = this.dialogSpinner;
    const formData = new FormData(this.formTarget);
    fetchWithToken('/imports', {
      method: 'POST',
      headers: { "Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      if (data.next_step) {
        this.nextStepBtnTarget.setAttribute('href', data.next_path);
        this.nextStepBtnTarget.click();
      } else {
        this.invalidForm(data.message)
      }
    })
  }
}
