import { ApplicationController } from "../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['brand', 'category']
  static values = {
    brand: String,
    category: String
  }

  connect() { 
    this.element.controller = this;
  }

  brandValueChanged() {
    this.brandTarget.innerHTML = this.brandValue;
  }

  categoryValueChanged() {
    this.categoryTarget.innerHTML = this.categoryValue;
  }

  refresh(data) {
    if (data?.brand) this.brandValue = data.brand;
    if (data?.category) this.categoryValue = data.category;
  }
}