import { ColumnFilterWithThreeOptionsController } from './column_filter_with_three_options_controller.js';

export default class extends ColumnFilterWithThreeOptionsController {
  get filterValues() {
    return ['asc', 'desc', ''];
  }

  get filterType() {
    return 'sorting';
  }
}
