function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

import { fetchWithToken } from '../../../javascript/utils/fetch_with_token';
import { ApplicationController } from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [ "name", "submitBtn" ]
  static values = {
    fieldName: String
  }

  connect() {
    this.#disableSubmitBtn();
    this.formContent = Object.fromEntries(new FormData(this.element));
  }

  submit(event) {
    event.preventDefault();
    fetchWithToken(this.element.action, {
      method: "PUT",
      headers: { 
        "Accept": "application/json"
      },
      body: new FormData(this.element)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        const fieldLine = document.querySelector(`[data-field-id="${data.field_id}"]`);
        if (fieldLine) {
          fieldLine.innerHTML = "";
          fieldLine.insertAdjacentHTML('beforeend', data.field_line_html);
          fieldLine.classList.add('bg-blue-100');
          setTimeout(() => fieldLine.classList.remove('bg-blue-100'), 3000);
        }
        this.rightSideOverlayController.close();
      } else {
        this.element.innerHTML = "";
        this.element.insertAdjacentHTML('beforeend', data.field_form_html);
      }
    })
  }

  check() {
    const queryString = new URLSearchParams({name: this.nameTarget.value}).toString();
    fetch(`/fields/check_name?${queryString}` , {
      method: 'GET',
      headers: {'Content-Type': 'application/json', Accept: 'application/json'},
    })
    .then(response => response.json())
    .then(data => {
      data.is_valid ? this.#setAsValid(data) : this.#setAsInvalid(data);
    })
  }

  setSubmitBtn(event) {
    console.log('hello');
    if (shallowEqual(this.formContent, Object.fromEntries(new FormData(this.element)))) {
      this.#setAsInvalid();
    } else {
      this.#setAsValid();
    }
    // if (this.nameTarget.value === this.fieldNameValue) {
    //   this.check()
    // } else {
    // };
  }

  #setAsValid(data) {
    this.nameTarget.classList.remove('is-invalid');
    this.#removeFeedback();
    this.submitBtnTarget.disabled = false;
    this.submitBtnTarget.classList.add('btn-outline-success')
    this.submitBtnTarget.classList.remove('btn-outline-secondary')
  }

  #setAsInvalid(data) {
    if (data) {
      this.nameTarget.classList.add('is-invalid');
      this.#removeFeedback();
      this.nameTarget.insertAdjacentHTML('afterend', this.#feedbackSpan(data.message));
    }
    this.submitBtnTarget.disabled = true;
    this.submitBtnTarget.classList.add('btn-outline-secondary')
    this.submitBtnTarget.classList.remove('btn-outline-success')
  }

  #removeFeedback() {
    const nextSibling = this.nameTarget.nextElementSibling;
    if (nextSibling && nextSibling.classList.contains('invalid-feedback')) nextSibling.remove();
  }

  #feedbackSpan(message) {
    return `<span class="invalid-feedback">${message}</span>`;
  }

  #disableSubmitBtn() {
    this.submitBtnTarget.disabled = true;
    this.submitBtnTarget.classList.add('btn-outline-secondary');
    this.submitBtnTarget.classList.remove('btn-outline-success');
  }
}
