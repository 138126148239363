import { Controller } from "stimulus"

export default class extends Controller {

  get filtersFormSubmit() {
    return document.querySelector('form.js-filters-form .js-filters-form-submit');
  }

  submit() {
    this.filtersFormSubmit.click();
  }
}
