import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["categoriesSelect", "resetBtn"]

  get fieldsTableContainer() {
    return document.querySelector('[data-fields-table-container]');
  }

  get formEntriesObject() {
    return Object.fromEntries(new FormData(this.element))
  }

  get formIsEmpty() {
    return !Object.values(this.formEntriesObject).find(v => v != '');
  }

  connect() {
    this.setCategoriesSelectColor();
    this.displayResetBtn();
  }
  
  displayResetBtn() {
    this.formIsEmpty ? this.resetBtnTarget.classList.add('d-none') : this.resetBtnTarget.classList.remove('d-none');
  }
  
  submit(event) {
    event.preventDefault();
    this.setCategoriesSelectColor();
    this.displayResetBtn();
    const url = `${this.element.action}?${new URLSearchParams(this.formEntriesObject).toString()}`;
    fetch(url, {
      headers: { 'Accept': 'application/json' }
    })
    .then(response => response.json())
    .then(data => {
      this.fieldsTableContainer.innerHTML = data.fields_table;
    })
  }
  
  setCategoriesSelectColor() {
    if (this.categoriesSelectTarget.value == '') {
      this.categoriesSelectTarget.classList.add('text-gray-400')
    } else {
      this.categoriesSelectTarget.classList.remove('text-gray-400')
    }
  }
}
