import { Controller } from "stimulus"
import { fetchWithToken } from '../utils/fetch_with_token';

export default class extends Controller {
  static values = {
    fieldId: Number,
    recipientId: Number,
  }

  toggle() {
    event.preventDefault();
    const bodyData = {
      field_id: this.fieldIdValue,
      recipient_id: this.recipientIdValue
    }
    fetchWithToken('/recipient_fields/toggle', {
      method: 'POST',
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(bodyData)
    })
    .then(response => response.json())
    .then(data => {
      if (data.is_active) {
        this.element.classList.add("active");
      } else {
        this.element.classList.remove("active");
      }
    })
  }

}
