import { Controller } from "stimulus"
import { fetchWithToken } from '../utils/fetch_with_token';

export default class extends Controller {
  static targets = ['btn', 'recipientContainer']
  static values = {
    productId: Number
  }

  connect() {
    this.element.controller = this;
  }

  get cableMessageController() {
    const cableMessage = document.querySelector('[data-controller="cable-message"]');
    return cableMessage?.controller;
  }

  refresh() {
    event.preventDefault();
    this.changeToLoadingBtn();
    fetchWithToken(`/products/${this.productIdValue}/update_pictures`, {
      method: 'POST',
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then(data => {
      this.cableMessageController.show("yellow", data.notice);
    })
  }

  changeToLoadingBtn() {
    this.btnTarget.innerHTML = `
      <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
      <span class="sr-only">Loading...</span>
      <span>Chargement des images...</span>
    `;
    this.btnTarget.classList.remove('btn-outline-primary');
    this.btnTarget.classList.add('btn-outline-info');
    this.btnTarget.classList.add('disabled');
    this.btnTarget.removeAttribute('href');
  }

  backToNormalBtn() {
    this.btnTarget.innerHTML = 'Charger les nouvelles photos';
    this.btnTarget.classList.add('btn-outline-primary');
    this.btnTarget.classList.remove('btn-outline-info');
    this.btnTarget.classList.remove('disabled');
    this.btnTarget.setAttribute('href', '');
  }
}
