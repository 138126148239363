import { Controller } from "stimulus"

export default class extends Controller {
  static values = { imageUrl: String }

  connect() {
    if (!this.imageUrlValue.includes('.jpg')) return;

    this.element.innerHTML = `<img src='${this.imageUrlValue}' class='light-shadow'>`;
  }
}
