import { ApplicationController } from "./application_controller";

export default class extends ApplicationController {
  static values = {
    path: String
  }

  static targets = ['btn']

  show(event) {
    event.preventDefault();
    fetch(this.pathValue, {
      method: 'GET',
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then(data => {
      if (data.forbidden) return;

      this.rightSideOverlayController.refreshBody(data.html);
      this.rightSideOverlayController.show();
    })
  }

  highlightBtn(event) {
    if (!this.hasBtnTarget) return;

    this.btnTarget.classList.add('text-blue-600');
  }

  resetBtn(event) {
    if (!this.hasBtnTarget) return;

    this.btnTarget.classList.remove('text-blue-600');
  }
}
