import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container" ]
  static values = {
    imageUrl: String
  }

  connect() {
    if (!this.hasContainerTarget || this.imageUrlValue == '' || !this.imageUrlValue.includes('.jpg')) return;
    this.containerTarget.innerHTML = `<img src='${this.imageUrlValue}' class='light-shadow'>`;
  }
}
