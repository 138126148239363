import { ApplicationController } from "../../../javascript/controllers/application_controller";
import swal from 'sweetalert';

export default class extends ApplicationController {
  get waitingForAcceptanceOrdersSectionController() {
    const ordersSections = Array.from(document.querySelectorAll('[data-controller="dashboard--orders-section"]'))
    if (ordersSections.length === 0) return;

    const ordersSection = ordersSections.find(section => section.id === "b2b_waiting_for_acceptance");
    if (!ordersSection) return;

    return ordersSection['dashboard--orders-section']
  }

  get waitingForAcceptanceIntegrationOrdersSectionController() {
    const ordersSections = Array.from(document.querySelectorAll('[data-controller="dashboard--orders-section"]'))
    if (ordersSections.length === 0) return;

    const ordersSection = ordersSections.find(section => section.id === "b2b_waiting_for_acceptance_integration");
    if (!ordersSection) return;

    return ordersSection['dashboard--orders-section']
  }

  accept() {
    this.element.innerHTML = `
      Accepter la commande
      <div class="spinner-grow spinner-grow-sm text-dark ml-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    `
    this.fetchWithToken(`/orders/${this.orderIdValue}/send_acceptance`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
    })
    .then(response => response.json())
    .then(data => {
      if (!data.success) {
        // TODO => afficher le message d'erreur.
        return
      }
      if (data.current_status_html) this.orderCurrentStatusControllers.forEach(el => el.refresh(data.current_status_html));
      if (data.waiting_for_acceptance_orders_section_html) this.waitingForAcceptanceOrdersSectionController.refresh(data.waiting_for_acceptance_orders_section_html);
      if (data.waiting_for_acceptance_integration_orders_section_html) this.waitingForAcceptanceIntegrationOrdersSectionController.refresh(data.waiting_for_acceptance_integration_orders_section_html);
      this.rightSideOverlayController.refreshBody(data.order_show_html);
    });
  }

  call() {
    event.preventDefault();
    swal({
      title: "Attention, cette action n'est pas réversible",
      text: `Êtes-vous sûre de vouloir accepter cette commande?`,
      icon: "warning",
      buttons: {
        confirm: {
          text: "Accepter la commande!!",
          value: "confirm",
          className: "btn btn-success",
        },
        test: {
          text: "Annuler",
          value: "test",
          className: "btn btn-secondary",
        },
      },
      dangerMode: true,
    })
    .then((value) => {
      switch (value) {
        case "confirm":
          this.accept();
          break;
        case "test":
          break;
      }
    })
  }
}
