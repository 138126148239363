import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "name", "submitBtn" ]

  connect() {
    this.check();
  }

  check() {
    this.nameTarget.value == '' ? this.setAsInvalid() : this.setAsValid()
  }

  setAsValid() {
    this.submitBtnTarget.disabled = false;
    this.submitBtnTarget.classList.add('btn-outline-success')
    this.submitBtnTarget.classList.remove('btn-outline-secondary')
  }

  setAsInvalid() {
    this.submitBtnTarget.disabled = true;
    this.submitBtnTarget.classList.add('btn-outline-secondary')
    this.submitBtnTarget.classList.remove('btn-outline-success')
  }
}
