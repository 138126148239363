import { Controller } from "stimulus"
import { fetchWithToken } from '../utils/fetch_with_token';

export default class extends Controller {
  static targets = [ "label", 'input' ]
  static values = {
    productId: Number,
    path: String,
    recipient: String
  }

  get productErrorsContainer() {
    return document.querySelector(`.js-product-${this.productIdValue}-errors`);
  }

  change() {
    this.labelTarget.click();
  }

  submit() {
    const bodyData = {
      attribute: `publishable_to_${this.recipientValue}`,
      new_value: this.inputTarget.checked
    }
    fetchWithToken(this.pathValue, {
      method: "POST",
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(bodyData)
    })
    .then(response => response.json())
    .then(data => {
      this.inputTarget.checked = data.value;
      this.productErrorsContainer.innerHTML = data.error ? data.error : "";
    });
  }
}
