import { Controller } from "stimulus";

export class ColumnFilterWithThreeOptionsController extends Controller {
  static values = {
    attribute: String,
    fieldId: Number
  }

  get filtersForm() {
    return document.querySelector('form.js-filters-form');
  }

  get filtersFormSubmit() {
    return document.querySelector('.js-filters-form-submit');
  }

  get attributeInputs() {
    return document.querySelectorAll(`.js-${this.filterType}-atribute-input`);
  }

  get fieldIdInputs() {
    return document.querySelectorAll(`.js-${this.filterType}-field-id-input`);
  }

  get valueInputs() {
    return document.querySelectorAll(`.js-${this.filterType}-value-input`);
  }

  get urlParams() {
    return new URLSearchParams(window.location.search);
  }

  get filterAttributeParam() {
    return this.urlParams.get(`filters[${this.filterType}[attribute]]`);
  }

  get filterFieldIdParam() {
    const fieldId = this.urlParams.get(`filters[${this.filterType}[field_id]]`);
    if(!fieldId) return null;
    return parseInt(fieldId);
  }

  get filterValueParam() {
    const value = this.urlParams.get(`filters[${this.filterType}[value]]`);
    if (!value) return null;
    return value.toLowerCase();
  }

  get isProductColumn() {
    if (this.attributeValue != '') return true;
    return false;
  }

  get isActive() {
    // return true si le filtre est actif sur la colonne (valeur 0 ou 1)
    if (this.filterValueParam == "" || this.filterValueParam == null) return false;
    if (!(this.filterAttributeParam == "" || this.filterAttributeParam == null)) {
      if (this.attributeValue === this.filterAttributeParam) return true;
    }
    if (!(this.filterFieldIdParam == "" || this.filterFieldIdParam == null)) {
      if (this.fieldIdValue === this.filterFieldIdParam) return true;
    }
    return false;
  }

  applyFilter() {
    if (this.isActive) {
      if (this.filterValueParam == this.filterValues[0]) {
        this.setValueInputsValue(this.filterValues[1])
      }
      if (this.filterValueParam == this.filterValues[1]) {
        this.resetFilter();
      }
    } else {
      this.setAttributeAndFieldIdInputsValues();
      this.setValueInputsValue(this.filterValues[0]);
    }
    this.filtersFormSubmit.click();
  }

  applyFilterOne() {
    if (this.isActive && this.filterValueParam == this.filterValues[0]) {
      this.resetFilter();
    } else {
      this.setAttributeAndFieldIdInputsValues();
      this.setValueInputsValue(this.filterValues[0]);
    }
    this.filtersFormSubmit.click();
  }

  applyFilterTwo() {
    if (this.isActive && this.filterValueParam == this.filterValues[1]) {
      this.resetFilter();
    } else {
      this.setAttributeAndFieldIdInputsValues();
      this.setValueInputsValue(this.filterValues[1]);
    }
    this.filtersFormSubmit.click();
  }

  applyFilterThree() {
    if (this.isActive && this.filterValueParam == this.filterValues[2]) {
      this.resetFilter();
    } else {
      this.setAttributeAndFieldIdInputsValues();
      this.setValueInputsValue(this.filterValues[2]);
    }
    this.filtersFormSubmit.click();
  }

  resetFilter() {
    this.setValueInputsValue(this.filterValues[2]);
    this.attributeInputs.forEach(input => input.value = "");
    this.fieldIdInputs.forEach(input => input.value = "");
  }

  setAttributeAndFieldIdInputsValues() {
    if (this.isProductColumn) {
      this.attributeInputs.forEach(input => input.value = this.attributeValue);
      this.fieldIdInputs.forEach(input => input.value = "");
    } else {
      this.attributeInputs.forEach(input => input.value = "");
      this.fieldIdInputs.forEach(input => input.value = this.fieldIdValue);
    }
  }

  setValueInputsValue(newValue) {
    this.valueInputs.forEach(input => input.value = newValue)
  }
}
