import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element[this.identifier] = this;
  }

  refresh(html) {
    this.element.outerHTML = html;
  }
}
