const turbolinksForGetForms = () => {
  document.querySelectorAll('form[method=get][data-remote=true]').forEach(form => {
    form.addEventListener('ajax:beforeSend', function (event) {
      Turbolinks.visit(event.detail[1].url);
      event.preventDefault();
    });
  })
}

export { turbolinksForGetForms };
