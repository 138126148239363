import flatpickr from "flatpickr";


const initFlatpickr = () => {
  initSingleDatePickr();
  initOrderContactDataExportPickr();
  initDateRangePickr();
}

const initSingleDatePickr = () => {
  flatpickr(".datepicker", {});
}

const initOrderContactDataExportPickr = () => {
  flatpickr(".export-datepicker", {
    minDate: "2020-01-01"
  });
}

const initDateRangePickr = () => {
  flatpickr(".date-range-picker", {
    mode: "range",
    // defaultDate: ["2016-10-10", "2016-10-20"]
  });
}

export { initFlatpickr };
