// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require("chartkick")
// require("chart.js")
require("chartkick").use(require("highcharts"))


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";
// import "stylesheets/application"

// Internal imports, e.g:
import { turbolinksForGetForms } from '../utils/turbolinks_for_get_forms';
import { initFlatpickr } from '../plugins/init_flatpickr';
import { initSlimSelects } from '../plugins/init_slim_selects';
import { initTooltip } from '../plugins/init_tooltip';
import { initChartkick } from '../plugins/init_chartkick';

import { fieldModal } from '../components/field_modal';
import { stickyCell } from '../components/sticky_cell';
import { emptySessionSelection } from '../components/empty_session_selection';
import { productSelection } from '../components/product_selection';
import { hideProductsTableFiltersPopovers } from '../components/hide_products_table_filters_popovers';
import { toggleGraph } from '../components/toggle_graph';

import { initBgJobNotificationsChannel } from '../channels/bg_job_notifications_channel';

document.addEventListener('turbolinks:load', () => {
  turbolinksForGetForms();
  initFlatpickr();
  initSlimSelects();
  initTooltip();
  initChartkick();

  fieldModal();
  stickyCell();
  // emptySessionSelection(); // attention à l'ordre => emptySessionSelection en 1, et productSelection en 2.
  productSelection(); // attention à l'ordre => emptySessionSelection en 1, et productSelection en 2.
  hideProductsTableFiltersPopovers();
  toggleGraph();

  initBgJobNotificationsChannel();
});

import "controllers"
