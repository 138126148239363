import { Controller } from "stimulus";
import swal from 'sweetalert';
import { fetchWithToken } from '../../utils/fetch_with_token';
import { manageTextareaHeight } from '../../components/manage_textarea_height';
import { initSlimSelects } from '../../plugins/init_slim_selects';

export default class extends Controller {
  static targets = [ "input", "formControl", "productCell", "formGroup" ]

  static values = {
    productId: Number,
    channelId: Number,
    fieldId: Number,
    attribute: String,
    type: String,
    editOn: Boolean,

    value: String,
    html: String
  }

  connect() {
    this.element['controller'] = this;
    const cellParent = this.element.closest('[class^="c-cell"]');
    if (!cellParent) return;
    if (cellParent.classList.contains('boolean-cell')) return;
    cellParent.addEventListener('click', (event) => {
      if (event.target != event.currentTarget) return;
      this.editOn();
    })
  }

  get isProductAttibute() {
    return this.attributeValue != '';
  }

  get isFieldValue() {
    return this.fieldIdValue != '';
  }

  get isChannelValue() {
    return this.channelIdValue != '';
  }

  get productShowBrand() {
    return document.getElementById('show-product-brand');
  }

  get productShowTitle() {
    return document.getElementById('show-product-title');
  }

  get productErrorsContainer() {
    return document.querySelector(`.js-product-${this.productIdValue}-errors`);
  }

  productShowPublishable(recipientName) {
    return document.querySelector(`.c-product-show__header__ctas #publishable_to_${recipientName}`);
  }

  publishableController(productId, recipient) {
    let selector = `[data-${this.identifier}-product-id-value='${productId}']`;
    selector += `[data-${this.identifier}-attribute-value='publishable_to_${recipient}']`;
    let buddy = document.querySelector(selector);
    if (buddy) return buddy.controller;
  }

  buddyController(productId) {
    // product attribute buddy :
    let selector = `[data-${this.identifier}-product-id-value='${productId}']`;
    selector += `[data-${this.identifier}-attribute-value='${this.attributeValue}']`;
    let buddy = document.querySelector(selector);
    if (buddy) return buddy.controller;
    // channel_product buddy :
    selector = `[data-${this.identifier}-product-id-value='${productId}']`;
    selector += `[data-${this.identifier}-channel-id-value='${this.channelIdValue}']`;
    buddy = document.querySelector(selector);
    if (buddy) return buddy.controller;
    // product_field buddy :
    selector = `[data-${this.identifier}-product-id-value='${productId}']`;
    selector += `[data-${this.identifier}-field-id-value='${this.fieldIdValue}']`;
    buddy = document.querySelector(selector);
    if (buddy) return buddy.controller;
  }

  editOn() {
    if (this.editOnValue) return;
    this.editOnValue = true;
    // on sauve le html 'de base' pour le réutiliser plus tard
    this.htmlValue = this.element.innerHTML;

    // on remplace le contenu de la cell avec le html de l'edit et on focus sur l'input
    this.element.innerHTML = "";
    this.element.append(this.editOnHTML());

    // activation de slim select sur l'element ajouté au DOM
    let pendingSlimSelect = this.element.querySelector('.pending-slim-select');
    if (pendingSlimSelect) {
      pendingSlimSelect.classList.remove('pending-slim-select');
      pendingSlimSelect.classList.add('slim-select');
    }
    pendingSlimSelect = this.element.querySelector('.pending-addable-slim-select');
    if (pendingSlimSelect) {
      pendingSlimSelect.classList.remove('pending-addable-slim-select');
      pendingSlimSelect.classList.add('addable-slim-select');
    }
    initSlimSelects(this.element);

    // activation de la taille dynamique des textarea
    const textarea = this.element.querySelector('textarea');
    if (textarea) manageTextareaHeight(textarea);

    // ajout du focus
    this.element.querySelector('.js-input').focus();
  }

  editOff() {
    this.editOnValue = false;
    // on remplace le contenu de la cell avec le html 'de base'
    this.element.innerHTML = "";
    this.element.insertAdjacentHTML('beforeend', this.htmlValue);
    // on update la valeur du form-control (elle a pu être modifée)
    this.formControlTarget.innerText = this.valueValue;
    this.formControlTarget.classList.remove('is-valid');
    this.formControlTarget.classList.remove('is-invalid');
    this.formControlTarget.classList.remove('is-unchanged');
  }

  submit() {
    if (event.type == "keyup" && event.key != "Enter") return;
    if (event.type == "keyup" && event.key === "Enter" && !event.ctrlKey) return;

    let selection = sessionStorage.getItem('products_ids');
    if (selection && JSON.parse(selection).length != 1 && JSON.parse(selection).length <= 15 && JSON.parse(selection).map(id => parseInt(id)).includes(this.productIdValue)) {
      selection = JSON.parse(selection);
      swal({
        title: "Appliquer la modification à toute la sélection ?",
        text: `Juste pour être sûr : vous êtes sur le point d'appliquer la nouvelle valeur aux ${selection.length} produits sélectionnés !`,
        icon: "warning",
        buttons: {
          apply_to_selection: {
            text: "oui, merci.",
            value: "apply_to_selection",
            className: "btn btn-success",
          },
          only_clicked: {
            text: "Non, uniquement au produit modifié.",
            value: "only_clicked",
            className: "btn btn-primary",
          },
        },
        dangerMode: true,
      })
      .then((value) => {
        switch (value) {

          case "apply_to_selection":
            selection.forEach(productId => this.triggerRightFetch(productId));
            break;

          case "only_clicked":
            this.triggerRightFetch();
            break;

          default:
            swal("C'est bon, on n'a touché à rien.😶");
        }
      });
    } else {
      this.triggerRightFetch();
    }
  }

  triggerRightFetch(productId = this.productIdValue) {
    if (this.typeValue == 'boolean') {
      if (this.isProductAttibute) this.toggleProduct(productId);
      if (this.isChannelValue) this.toggleChannel(productId);
      if (this.isFieldValue) this.toggleField(productId);
    } else {
      this.isProductAttibute ? this.submitProduct(productId) : this.submitProductField(productId);
    }
  }

  toggleField(productId = this.productIdValue) {
    const bodyData = {
      new_value: this.valueValue != 'true',
      product_id: productId,
      field_id: this.fieldIdValue
    }
    fetchWithToken('/product_fields/toggle_boolean', {
      method: "POST",
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(bodyData)
    })
    .then(response => response.json())
    .then(data => {
      this.toggleBooleanValue(data);
    })
  }

  toggleChannel(productId = this.productIdValue) {
    const bodyData = {
      new_value: this.valueValue != 'true',
      product_id: productId,
      channel_id: this.channelIdValue
    }
    fetchWithToken('/selling_channel_products/toggle_boolean', {
      method: "POST",
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(bodyData)
    })
    .then(response => response.json())
    .then(data => {
      this.toggleBooleanValue(data);
    })
  }

  toggleProduct(productId = this.productIdValue) {
    const path = `/products/${productId}/toggle_boolean`
    const bodyData = {
      attribute: this.attributeValue,
      new_value: this.valueValue != 'true',
      product_id: this.productIdValue
    }
    fetchWithToken(path, {
      method: "POST",
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(bodyData)
    })
    .then(response => response.json())
    .then(data => {
      if (data.message) return;
      this.toggleBooleanValue(data);
    })
  }

  toggleBooleanValue(data) {
    if (data.product_id === parseInt(this.productIdValue)) {
      if (data.hasOwnProperty('value')) this.valueValue = data.value;
      if (data.hasOwnProperty('checked')) {
        this.productCellTarget.innerHTML = `<i class="far fa-${data.checked ? 'check-' : ''}square"></i>`;
      }
      this.productErrorsContainer.innerHTML = data.error ? data.error : "";
    } else {
      const buddy = this.buddyController(data.product_id);
      if (buddy) {
        if (data.hasOwnProperty('value')) buddy.valueValue = data.value;
        if (data.hasOwnProperty('checked')) {
          buddy.productCellTarget.innerHTML = `<i class="far fa-${data.checked ? 'check-' : ''}square"></i>`;
        }
        buddy.productErrorsContainer.innerHTML = data.error ? data.error : "";
      }
    }
    this.setPublishable(data);
  }

  setPublishable(data) {
    if (!data.publishable) return;
    Object.keys(data.publishable).forEach(recipient => {
      const publishable = data.publishable[recipient];
      if (this.productShowPublishable(recipient)) this.productShowPublishable(recipient).checked = publishable;
      if (this.attributeValue === `publishable_to_${recipient}`) return;

      const publishableController = this.publishableController(data.product_id, recipient);
      if (!publishableController) return;
      publishableController.valueValue = publishable;
      publishableController.productCellTarget.innerHTML = `<i class="far fa-${publishable ? 'check-' : ''}square"></i>`;

    })
  }

  editHTML(templateId, formGroupSelector) {
    // on créé une nouvelle div avec la class c-product-value
    const template = document.createElement("div");
    template.classList.add("c-product-value");
    // on clone le bon form-group du template et on set la value
    const selector = `${templateId} ${formGroupSelector}`;
    const formGroup = document.querySelector(selector).cloneNode(true);
    formGroup.querySelector('.js-input').value = this.valueValue;
    // on clone les boutons
    const buttons = document.querySelector(`${templateId} .c-product-value__buttons`).cloneNode(true);
    // on ajoute le formGroup et les boutons au template
    template.appendChild(formGroup);
    template.appendChild(buttons);
    return template;
  }

  editProductHTML() {
    const templateId = '#product-edit-template';
    const formGroupSelector = `.form-group.product_${this.attributeValue}`;
    return this.editHTML(templateId, formGroupSelector);
  }

  editProductFieldHTML() {
    const templateId = '#product-field-edit-template';
    const formGroupSelector = `.form-group.product_field_value`;
    return this.editHTML(templateId, formGroupSelector);
  }

  editOnHTML() {
    return this.isProductAttibute ? this.editProductHTML() : this.editProductFieldHTML();
  }

  submitFetch(path, verb, bodyData) {
    fetchWithToken(path, {
      method: verb,
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(bodyData)
    })
    .then(response => response.json())
    .then(data => {
      if (data.product_id === parseInt(this.productIdValue)) {
        this.productErrorsContainer.innerHTML = "";
        // alors le user est sur l'input du produit
        if (data.is_valid) {
          this.valueValue = data.value;
          this.editOff();
          this.formControlTarget.classList.remove("is-invalid");
          this.formControlTarget.classList.add("is-valid");
          this.formControlTarget.addEventListener('animationend', (event) => {
            event.currentTarget.classList.remove("is-valid")
          })
        } else {
          this.inputTarget.classList.add('is-invalid');
          this.formGroupTarget.querySelector('[class*="feedback"]')?.remove();
          const feedback = `<span class='invalid-feedback'>${data.message}</span>`;
          this.formGroupTarget.insertAdjacentHTML('beforeend', feedback);
        }
      } else {
        // alors le user est sur l'input d'un autre produit
        const buddy = this.buddyController(data.product_id);
        if (!buddy) return;
        buddy.productErrorsContainer.innerHTML = "";
        if (data.is_valid) {
          buddy.valueValue = data.value;
          // si le buddy est en mode editOff
          if (buddy.hasFormControlTarget) {
            buddy.formControlTarget.classList.remove("is-invalid");
            buddy.formControlTarget.innerText = data.value;
            buddy.formControlTarget.classList.add("is-valid");
            buddy.formControlTarget.addEventListener('animationend', (event) => {
              event.currentTarget.classList.remove("is-valid")
            })
          }
          // si le buddy est en mode editOn
          if (buddy.hasInputTarget) {
            buddy.inputTarget.classList.remove("is-invalid");
            buddy.inputTarget.value = data.value;
            buddy.inputTarget.classList.add("is-valid");
            buddy.inputTarget.addEventListener('animationend', (event) => {
              event.currentTarget.classList.remove("is-valid")
            })
          }
        } else {
          if (buddy.hasFormControlTarget) {
            buddy.formControlTarget.classList.add("is-unchanged");
            buddy.formControlTarget.addEventListener('animationend', (event) => {
              event.currentTarget.classList.remove("is-unchanged")
            })
          }
        }
      }
      this.setPublishable(data);
      // sur la show, il faut mettre à jour la marque et le titre dans l'entête
      // s'ils sont mis à jour
      if (data.attribute == "brand" && this.productShowBrand) {
        this.productShowBrand.innerText = data.value;
      }
      if (data.field_name == 'Titre' && this.productShowTitle) {
        this.productShowTitle.innerText = data.value;
      }
    });
  }

  submitProduct(productId = this.productIdValue) {
    const path = `${window.location.origin}/products/${productId}`
    const verb = "PUT";
    const bodyData = { attribute: this.attributeValue, value: this.inputTarget.value };
    this.submitFetch(path, verb, bodyData)
  }

  submitProductField(productId = this.productIdValue) {
    const path = `${window.location.origin}/product_fields/create_or_update`;
    const verb = "POST";
    let bodyData = {
      product_id: productId,
      field_id: this.fieldIdValue,
      value: this.inputTarget.value
    }
    this.submitFetch(path, verb, bodyData)
  }
}
