import { Controller } from "stimulus"
import { fetchWithToken } from '../utils/fetch_with_token';

export default class extends Controller {
  static targets = [ "prodcat", "span", "input", "cancel" ]
  static values = { path: String, name: String }

  get newName() {
    return this.inputTarget.value;
  }

  editOnHTML() {
    const template = document.querySelector('#prodcat-template > div').cloneNode(true);
    template.querySelector('.js-prodcat-name').value = this.nameValue;
    return template
  }

  editOffHTML() {
    return `
      <div class="c-prodcat__name--off">
        <span class="form-control"
          data-prodcat-target='span'
          data-action='click->prodcat#editOn'>
          ${this.nameValue}
        </span>
        <div class="c-prodcat__buttons">
          <button class="btn btn-success btn-sm disabled">ok</button>
          <button class="btn btn-outline-danger btn-sm ml-1 disabled">ko</button>
        </div>
      </div>
    `
  }

  editOn() {
    this.prodcatTarget.classList.add('active');
    this.prodcatTarget.innerHTML = "";
    this.prodcatTarget.append(this.editOnHTML());
    this.prodcatTarget.querySelector('.js-prodcat-name').focus();
  }

  editOff(event) {
    if (event) event.preventDefault();
    this.prodcatTarget.classList.remove('active');
    this.prodcatTarget.innerHTML = "";
    this.prodcatTarget.insertAdjacentHTML('beforeend', this.editOffHTML());
  }

  submit() {
    if (event.type == "keyup" && event.key != "Enter") return;
    fetchWithToken(this.pathValue, {
      method: "PUT",
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({ product_category: { name: this.newName }})
    })
    .then(response => response.json())
    .then(data => {
      if (data.is_valid) {
        this.nameValue = data.name;
        this.editOff();
        this.spanTarget.classList.add("is-valid");
      } else {
        this.prodcatTarget.innerHTML = data.form;
      }
    })
  }
}
