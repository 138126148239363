import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element.controller = this;
    this.fixedPositionning();
  }

  show(color = "yellow", message) {
    this.removeBgClasses();
    this.element.classList.add(`bg-${color}`);
    this.element.innerText = message;
    this.element.classList.remove('d-none');
  }

  hide() {
    this.element.classList.add('d-none');
  }

  removeBgClasses() {
    Array.from(this.element.classList).forEach(className => {
      if (className.includes('bg-')) this.element.classList.remove(className);
    })
  }

  fixedPositionning() {
    let navbar = document.querySelector('.desktop-navbar');
    if (!navbar || navbar.style.display === "none") {
      navbar = document.querySelector('.mobile-navbar');
    }
    const navbarHeight = navbar.offsetHeight;

    window.addEventListener('scroll', () => {
      if (window.scrollY > navbarHeight) {
        this.element.classList.add('fixed')
      } else {
        this.element.classList.remove('fixed')
      }
    })
  }
}
