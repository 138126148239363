import { Controller } from "stimulus"
import Sortable from 'sortablejs';
import { fetchWithToken } from '../utils/fetch_with_token';

export default class extends Controller {
  static targets = ['pending', 'selected']
  // static targets = ['pending', 'selected', 'undesired']
  static values = {
    recipient: String,
    path: String
  }

  connect() {
    if (this.hasPendingTarget) {
      new Sortable(this.pendingTarget, {
          group: 'shared', // set both lists to same group
          animation: 150,
          onEnd: (event) => {
            this.sendRecipientImages();
          }
      });
    }
    if (this.hasSelectedTarget) {
      new Sortable(this.selectedTarget, {
          group: 'shared', // set both lists to same group
          animation: 150,
          onEnd: (event) => {
            this.sendRecipientImages();
          }
      });
    }
  }

  sendRecipientImages() {
    console.log(this.recipientImages)
    fetchWithToken(this.pathValue, {
      method: "POST",
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify(this.recipientImages)
    })
    .then(response => response.json())
    .then(data => {
      this.recipientImagesLineController(this.recipientValue).refreshLine(data.html);
      console.log(data);
    });
  }

  get selectedPicturesWithIndex() {
    let pictures = this.selectedTarget.querySelectorAll(`img`);
    if (pictures.length === 0) return;
    pictures = Array.from(pictures);
    const orderedPictures = {}
    pictures.forEach(picture => {
      orderedPictures[pictures.indexOf(picture)] = picture.dataset.url;
    })
    return orderedPictures;
  }

  get recipientImages() {
    const data = {};
    data[`images_${this.recipientValue}`] = this.selectedPicturesWithIndex;
    return data;
  }

  get recipientImagesLineControllers() {
    const elements = Array.from(document.querySelectorAll(`[data-controller="products--recipient-images-section"]`));
    return elements.map(element => element.controller);
  }

  recipientImagesLineController(recipient) {
    return this.recipientImagesLineControllers.find(controller => controller.recipientValue === recipient);
  }
}
