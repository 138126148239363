const productSelection = () => {
  selectOnClick();
  selectFromStorage();
}

const selectOnClick = () => {
  let lastClickedIndex = null;
  let currentIndex = null;
  let checkboxesRange = null

  const boxes = checkboxes();

  boxes.forEach((checkbox) => {
    checkbox.addEventListener('click', (event) => {
      const initialState = event.currentTarget.dataset.checked === "true";
      const newState = !initialState;

      event.currentTarget.dataset.checked = newState;
      currentIndex = boxes.findIndex(checkbox => checkbox === event.currentTarget);

      if (!(lastClickedIndex !== null && event.shiftKey) || lastClickedIndex === currentIndex) {

        lastClickedIndex = currentIndex;

      } else {
        if (lastClickedIndex > currentIndex) lastClickedIndex++;

        const indexes = [lastClickedIndex, currentIndex].sort();
        boxes.slice(...indexes).forEach(checkbox => checkbox.dataset.checked = newState);

        lastClickedIndex = currentIndex;
      }

      manageSelection();

    });
  });
}

const selectFromStorage = () => {
  if (checkboxes().length === 0 || !sessionStorage.getItem('products_ids')) {
    manageSelection();
    return;
  }
  let productIds = JSON.parse(sessionStorage.getItem('products_ids'));
  productIds = productIds.map(id => id.toString());
  const selectedCheckboxes = checkboxes().filter(checkbox => productIds.includes(checkbox.dataset.productId));
  selectedCheckboxes.forEach(checkbox => checkbox.dataset.checked = true)
  manageSelection();
}

const manageSelectAllButton = () => {
  const button = document.querySelector('.c-header-cell.js-selection-checkbox');
  if (!button) return;
  const showSelectionController = document.querySelector('[data-controller="products-table--show-selection"]').controller;
  button.removeEventListener('click', unselectAll);
  button.removeEventListener('click', selectAll);
  button.querySelectorAll('i').forEach(i => i.classList.add('d-none'));
  const selection = sessionStorage.getItem('products_ids');
  if (selection) {
    button.querySelector('.fa-minus-square').classList.remove('d-none');
    button.addEventListener('click', unselectAll);
    showSelectionController.showCtas();
  } else {
    button.querySelector('.fa-check-square').classList.remove('d-none');
    button.addEventListener('click', selectAll);
    showSelectionController.hideCtas();
  }
}

const selectAll = () => {
  // on ajoute les ids de tous les produits à sessionStorage
  const el = document.querySelector('[data-products-ids]');
  if (!el) return;
  const productsIds = JSON.parse(el.dataset.productsIds);
  sessionStorage.setItem('products_ids', JSON.stringify(productsIds));
  // on check tous les produits affichés
  checkboxes().forEach(checkbox => checkbox.dataset.checked = true);
  manageSelection();
}

const unselectAll = () => {
  // on vide sessionStorage
  sessionStorage.clear();
  // on décheck tous les produits affichés
  checkboxes().forEach(checkbox => checkbox.dataset.checked = false);
  manageSelection();
}

const manageSelection = () => {
  addSelectedClassToProductLines();
  addSelectionToSessionStorage();
  manageSelectAllButton();
}

const addSelectedClassToProductLines = () => {
  const productsLines = document.querySelectorAll('.c-products__line');
  productsLines.forEach(line => line.classList.remove('selected'));

  selectedProductLines().forEach(line => line.classList.add('selected'));
}

const addSelectionToSessionStorage = () => {

  let previousSelection = JSON.parse(sessionStorage.getItem('products_ids'));
  if (!previousSelection) previousSelection = [];

  // pour mettre à jour sessionStorage, on supprime tous les produits de la page
  // et on ajoute ceux qui sont sélectionnés ensuite
  previousSelection = previousSelection.map(id => parseInt(id));
  let previousSelectionWithoutCurrentPage = previousSelection.filter(id => !checkboxesIds().includes(id));
  let newSelection = previousSelectionWithoutCurrentPage
  newSelection.push(selectedProductIds());
  newSelection = [...new Set(newSelection.flat())];

  // on vide sessionStorage si la selection est vide
  if (newSelection.length === 0) {
    sessionStorage.clear();
    return;
  }
  sessionStorage.setItem('products_ids', JSON.stringify(newSelection));
}

const checkboxes = () => {
  const selector = '.c-products__line .js-selection-checkbox .c-product-value';
  return Array.from(document.querySelectorAll(selector));
}

const checkboxesIds = () => {
  return checkboxes().map(cB => parseInt(cB.dataset.productId));
}

const selectedCheckboxes = () => {
  return checkboxes().filter(cB => cB.dataset.checked == "true")
}

const selectedProductIds = () => {
  return selectedCheckboxes().map(cB => parseInt(cB.dataset.productId));
}

const selectedProductLines = () => {
  return selectedCheckboxes().map(cB => cB.closest('.c-products__line'));
}

export { productSelection };
