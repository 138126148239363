import { ApplicationController } from "./application_controller";

export default class extends ApplicationController {
  static values = {
    path: String
  }

  connect() {
    console.log('hello there')
  }

  show() {
    fetch(this.pathValue, {
      method: 'GET',
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then(data => {
      this.rightSideOverlayController.refreshBody(data.html);
      this.rightSideOverlayController.show();
    })
  }
}
