function hideSideOverlay(event) {
  const overlay = document.getElementById('right-side-overlay');
  if (!overlay) return;
  
  const overlayController = overlay['shared--right-side-overlay'];
  if (!event.composedPath().find(el => isInStayOpenZone(el, overlay))) overlayController.close();
}

function isInStayOpenZone(el, overlay) {
  if (el.id === overlay.id) return true;
  if (el.classList?.contains('swal-overlay')) return true;
  return false;
}

import { initTooltip } from '../../javascript/plugins/init_tooltip';

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["body"]

  connect() { this.element[this.identifier] = this }

  close() {
    this.element.classList.remove('open');
  }

  show() {
    window.removeEventListener('click', hideSideOverlay)
    this.element.classList.add('open');
    setTimeout(() => window.addEventListener('click', hideSideOverlay), 1);
    setTimeout(() => {
      initTooltip();
    }, 1);
  }

  refreshBody(html) {
    if (typeof(html) === 'string') {
      this.bodyTarget.innerHTML = html;
    } else {
      this.bodyTarget.innerHTML = "";
      this.bodyTarget.appendChild(html);
    }
  }
}
