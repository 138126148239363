
import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    position: String,
    title: String
  }

  connect() {
    this.element.insertAdjacentHTML("afterend", this.iconHtml())
    this.element.controller = this;
  }

  iconHtml() {
    return `<i class="fa fa-light fa-circle-info ml-1" data-toggle="tooltip" data-placement="${this.positionValue}" title="${this.titleValue}"></i>`
  }
}
