import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['advanceFilters', 'marketplaceFilter', 'websiteFilter']

  get advanceFilterCheckboxes() {
    return Array.from(this.advanceFiltersTarget.querySelectorAll('input[type="checkbox"]'));
  }

  showMarketplaceAdvanceFilters() {
    if (event.currentTarget.value == 'amazon') {
      this.showAdvanceFilters();
    } else {
      this.hideAdvanceFilters();
    };
  }

  setMarketplaceValue() {
    if (event.currentTarget.value != "") this.marketplaceFilterTarget.value = '';
    this.hideAdvanceFilters();
  }

  setWebsiteValue() {
    if (event.currentTarget.value != "") this.websiteFilterTarget.value = '';
  }

  showAdvanceFilters() {
    this.advanceFiltersTarget.classList.add('d-flex');
    this.advanceFilterCheckboxes.forEach(checkbox => checkbox.checked = true);
  }

  hideAdvanceFilters() {
    this.advanceFiltersTarget.classList.remove('d-flex');
    this.advanceFilterCheckboxes.forEach(checkbox => checkbox.checked = false);
  }
}
