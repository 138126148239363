const hideProductsTableFiltersPopovers = () => {
  if (!document.getElementById('products-index')) return;
  window.addEventListener('click', (event) => {
    const contentFilterPopover = event.target.closest('.c-header-cell__content-filter-btn');
    if (contentFilterPopover) return;
    const popovers = document.querySelectorAll(".c-header-cell__content-filter-btn [class^='rg-popo-']")
    popovers.forEach(popover => popover.classList.add('d-none'));
  })
}

export { hideProductsTableFiltersPopovers };
