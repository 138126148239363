import { ApplicationController } from "./application_controller";

export default class extends ApplicationController {
  static targets = ['formContainer']

  get #formClone() {
    const form = this.formContainerTarget.querySelector('form');
    if (!form) return;

    const clonedForm = form.cloneNode(true);
    clonedForm.classList.remove('d-none');
    // 👇 pour éviter que les labels soient connectés au form original
    clonedForm.querySelectorAll('label').forEach(label => {
      label.setAttribute('for', label.getAttribute('for') + '_2')
    });
    clonedForm.querySelectorAll('input').forEach(input => {
      input.id = input.id + '_2';
    });
    return clonedForm;
  }

  show() {
    event.preventDefault();
    this.rightSideOverlayController.refreshBody(this.#rightSideOverlayContent());
    this.rightSideOverlayController.show();
  }

  #rightSideOverlayContent() {
    const div = document.createElement('div');
    div.insertAdjacentHTML("afterbegin", `<h1 class="text-2xl">Filtres avancés</h1>`);
    div.appendChild(this.#formClone)
    return div;
  }
}
