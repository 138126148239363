import Chartkick from "chartkick";
import Highcharts from "highcharts";
import moment from 'moment';

const initChartkick = () => {
  Highcharts.setOptions({
    global: {
      useUTC: false
    }
  });
  if (document.getElementById('pages-turnover_evolution')) {
    Chartkick.use(Highcharts)
    drawGlobalRevenuGraph("month");
    drawRevenuByMP("month");
    drawGlobalRevenuGraph("week");
    drawRevenuByMP("week");
    drawGlobalRevenuGraph("day");
    drawRevenuByMP("day");
    drawGlobalRevenuCompGraph("month");
  };
  if (document.getElementById('pages-sales_recap')) {
    drawPieChart();
    drawLineChart();
  }
  if (document.getElementById('sales-index')) {
    drawRSSaleGraph();
  }
}

const drawGlobalRevenuGraph = (period) => {
  const selector = 'ck-global-revenu-' + period;
  const element = document.getElementById(selector);
  if (!element) return;

  const data = JSON.parse(element.dataset.chart);

  if (period == "month") {
    var dateFormat = '%b %Y'
  } else if (period == "week") {
    var dateFormat = 'week from %A, %b %e, %Y'
  } else if (period == "day") {
    var dateFormat = '%A, %b %e, %Y'
  }

  new Chartkick.LineChart(selector, data, {
    adapter: "highcharts",
    legend: "bottom",
    label: "Chiffre d'affaires",
    suffix: "€",
    thousands: ".",
    decimal: ",",
    messages: { empty: "No data" },
    library: {
      tooltip: { xDateFormat: dateFormat },
    }
  });
}

const drawGlobalRevenuCompGraph = (period) => {
  const selector = 'ck-global-revenu-comp-' + period;
  const element = document.getElementById(selector);
  if (!element) return;

  const data = JSON.parse(element.dataset.chart);

  if (period == "month") {
    var dateFormat = '%b'
  } else if (period == "week") {
    var dateFormat = 'week from %A, %b %e, %Y'
  } else if (period == "day") {
    var dateFormat = '%A, %b %e, %Y'
  }

  new Chartkick.ColumnChart(selector, data, {
    legend: "bottom",
    label: "Chiffre d'affaires",
    suffix: "€",
    thousands: ".",
    decimal: ",",
    messages: { empty: "No data" },
    library: {
      global: { useUTC: false },
      tooltip: { xDateFormat: dateFormat }
    }
  });
}

const drawRevenuByMP = (period) => {
  const selector = 'ck-revenu-by-marketplace-' + period;
  const element = document.getElementById(selector);
  if (!element) return;

  const data = JSON.parse(element.dataset.chart);

  new Chartkick.ColumnChart(selector, data, {
    legend: "bottom",
    label: "Chiffre d'affaires",
    points: false,
    thousands: ".",
    decimal: ",",
    messages: { empty: "No data" },
    suffix: "€",
    decimal: ",",
    stacked: true,
    // colors: chartkickColors(data.length),
    library: {
      global: { useUTC: false }
    }
  });
}

const chartkickColors = (numberOfSeries) => {
  if (numberOfSeries == 6) {
    return ["#E20071", "#F4B53F", "#81B73E", "#283846", "#79B6E8", "#5DA1D8"];
  } else {
    return ["#5DA1D8", "#79B6E8", "#FF6425", "#283846", "#2125297d", "#81B73E", "#F4B53F", "#D82B47", "#B70001", "#E20071"]
    //        Amazon     AmazonP      BP     Cdiscount  CdiscountAV     Ebay       Fnac    laRedoute   Rakuten       VP
    // return ["#E20071", "#B70001", "#D82B47", "#F4B53F", "#81B73E", "#283846", "#FF6425", "#79B6E8", "#5DA1D8"];
    //         VP       Rakuten   laRedoute     Fnac      Ebay     Cdiscount      BP      AmazonP     Amazon
  }
}

const drawPieChart = () => {
  const elements = document.querySelectorAll('.pie-chart');
  elements.forEach(el => {
    const data = JSON.parse(el.dataset.chartData);
    const title = el.dataset.chartTitle;
    new Chartkick.PieChart(el.id, data, {
      title: title,
    })
  })
}

const drawLineChart = () => {
  const elements = document.querySelectorAll('.line-chart');
  elements.forEach(el => {
    const data = JSON.parse(el.dataset.chartData);
    const title = el.dataset.chartTitle;
    new Chartkick.LineChart(el.id, data, {
      title: title,
      height: "400px"
    })
  })
}

const drawRSSaleGraph = () => {
  const elements = document.querySelectorAll('.cl-sales-graph');
  elements.forEach(el => {
    const data = JSON.parse(el.dataset.chartData);
    const title = el.dataset.chartTitle;
    new Chartkick.LineChart(el.id, data, {
      legend: "bottom",
      label: "Unités vendues",
      suffix: "",
      thousands: ".",
      decimal: ",",
      messages: { empty: "No data" },
      library: {
        yAxis: { tickInterval: 1, allowDecimals: false }
      }
    })
  })
}

export { initChartkick };
