import { Controller } from "stimulus"
import swal from 'sweetalert';

export default class extends Controller {
  static values = {
    path: String
  }

  delete() {
    const link = document.createElement('a');
    link.appendChild(document.createTextNode("destroy"));
    link.href = this.pathValue;
    link.dataset.method = 'delete';
    link.classList.add('d-none');
    link.id = "import-delete-btn";
    document.body.appendChild(link);
    document.getElementById("import-delete-btn").click();
  }

  submit() {
    event.preventDefault();
    swal({
      title: "Une dernière petite confirmation 🙏",
      text: "Les imports supprimés ne sont plus accessibles.",
      icon: "warning",
      buttons: true,
    })
    .then(willDelete => {
      if (willDelete) {
        this.delete();
      } else {
        swal("On a bien fait de poser la question on dirait ! 😬")
      }
    })
  }
}
