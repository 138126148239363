import { Controller } from "stimulus";
import SlimSelect from 'slim-select';

export default class extends Controller {
  static targets = [ "popover" ]
  static values = {
    channelId: Number,
  }

  get channelContentsFiltersContainer() {
    return document.querySelector('.js-channel-content-filters-container');
  }

  get filtersFormInput() {
    return this.channelContentsFiltersContainer.querySelector(`[name='filters[channel[${this.channelIdValue}]]']`);
  }

  get filtersFormSubmit() {
    return document.querySelector('.js-filters-form-submit');
  }

  showPopover() {
    const popovers = document.querySelectorAll("[class^='rg-popo-']")
    popovers.forEach(popover => {
      if (popover != this.popoverTarget) popover.classList.add('d-none');
    });
    this.popoverTarget.classList.toggle('d-none');
  }

  removeFiltersFormInput() {
    if (this.filtersFormInput) this.filtersFormInput.remove();
  }

  reset() {
    event.preventDefault();
    this.removeFiltersFormInput();
    this.filtersFormSubmit.click();
  }

  checkedFilter() {
    this.setValueAndSubmit("true");
  }

  uncheckedFilter() {
    this.setValueAndSubmit("false");
  }

  setValueAndSubmit(value) {
    event.preventDefault();
    const input = this.inputForBoolean();
    input.value = value;
    this.removeFiltersFormInput();
    this.channelContentsFiltersContainer.appendChild(input);
    this.filtersFormSubmit.click();
  }

  inputForBoolean() {
    const input = document.createElement('input');
    input.setAttribute('name', `filters[channel[${this.channelIdValue}]]`);
    // input.value = this.selectTarget.value;
    return input;
  }
}
