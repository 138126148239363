import { Controller } from "stimulus";
import SlimSelect from 'slim-select';

export default class extends Controller {
  static targets = [ "popover", "select" ]
  static values = {
    attribute: String,
  }

  get selectedValues() {
    Array.from(this.selectTarget.options)
         .filter(option => option.selected)
         .map(option => option.value)
  }

  get filtersFormSubmit() {
    return document.querySelector('.js-filters-form-submit');
  }

  get filtersFormSelect() {
    return document.getElementById(`main_filters_form_filters_product[${this.attributeValue}]`);
  }

  get filtersFormSelectOptions() {
    return Array.from(this.filtersFormSelect.options);
  }

  resetfiltersFormSelect() {
    this.filtersFormSelectOptions.forEach(option => option.selected = false);
  }

  connect() {
    console.log(this.attributeValue)
    if (this.attributeValue.includes("publishable_to_")) return;
    if (!this.hasSelectTarget) return;
    new SlimSelect({
      select: this.selectTarget,
      onChange: (infos) => {
        this.resetfiltersFormSelect();
        infos.forEach(info => {
          const option = this.filtersFormSelectOptions.find(option => option.value === info['value']);
          if (option) option.selected = true;
        })
      }
    });
  }

  change() {
    const option = this.filtersFormSelectOptions.find(option => option.value === this.selectTarget.value);
    if (option) option.selected = true;
  }

  showPopover() {
    const popovers = document.querySelectorAll("[class^='rg-popo-']")
    popovers.forEach(popover => {
      if (popover != this.popoverTarget) popover.classList.add('d-none');
    });
    this.popoverTarget.classList.toggle('d-none');
  }

  submit() {
    this.filtersFormSubmit.click();
  }

  reset() {
    this.resetfiltersFormSelect();
    this.submit();
  }

  allValuesFilter() {
    event.preventDefault();
    this.resetfiltersFormSelect();
    const option = this.filtersFormSelectOptions.find(option => option.value === "all");
    if (option) option.selected = true;
    this.filtersFormSubmit.click();
  }

  checkedFilter() {
    event.preventDefault();
    this.resetfiltersFormSelect();
    const option = this.filtersFormSelectOptions.find(option => option.value === "true");
    if (option) option.selected = true;
    this.filtersFormSubmit.click();
  }

  uncheckedFilter() {
    event.preventDefault();
    this.resetfiltersFormSelect();
    const option = this.filtersFormSelectOptions.find(option => option.value === "false");
    if (option) option.selected = true;
    this.filtersFormSubmit.click();
  }

  withValueFilter() {
    event.preventDefault();
    this.resetfiltersFormSelect();
    const option = this.filtersFormSelectOptions.find(option => option.value === "with_value");
    if (option) option.selected = true;
    this.filtersFormSubmit.click();
  }

  withoutValueFilter() {
    event.preventDefault();
    this.resetfiltersFormSelect();
    const option = this.filtersFormSelectOptions.find(option => option.value === "empty_value");
    if (option) option.selected = true;
    this.filtersFormSubmit.click();
  }

  allValueFilter() {
    event.preventDefault();
    this.resetfiltersFormSelect();
    const option = this.filtersFormSelectOptions.find(option => option.value === "all_value");
    if (option) option.selected = true;
    this.filtersFormSubmit.click();
  }
}
