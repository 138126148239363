import consumer from "./consumer";

const initBgJobNotificationsChannel = () => {

  const userId = document.body.dataset.userId;
  if (!userId) return;

  const identifier = { channel: "BgJobNotificationsChannel", id: userId };

  // 👇 si une subscription existe déjà, on ne souscrit pas à nouveau
  const subscriptions = consumer.subscriptions.subscriptions;
  const previousSub = subscriptions.find(sub => {
    const id = JSON.parse(sub.identifier);
    return id.channel === identifier.channel && id.id === identifier.id
  })
  if (previousSub) return;

  consumer.subscriptions.create(identifier, {
    received(data) {
      if (data.job === "process_import_job") {
        processImportJob(data);
        return;
      };
      if (data.job?.includes('selection_publishable_job')) {
        publishSelection(data);
        return;
      }
      if (data.job === "translate_product_job") {
        translateProductJob(data);
        return;
      }
      if (data.job === "products/update_product_pictures/job") {
        fetchProductImagesJob(data);
      }
      showCableMessage(data)
    }
  })
}

const showCableMessage = (data) => {
  const cableMessage = document.querySelector('[data-controller="cable-message"]')?.controller;
  if (!cableMessage) return;
  cableMessage.show(data.color ? data.color : "yellow", data.message);
}

// 👇 cette méthode se chage de tout ce qu'il y a à faire lorsque l'exécution d'un
// import en BG est terminée
const processImportJob = (cableData) => {
  const showContainer = document.getElementById('imports-show');
  const indexContainer = document.getElementById('imports-index');
  if (showContainer || indexContainer) {
    fetch(`/imports/${cableData.import_id}`, {
      headers: { "Accept": "application/json" }
    })
    .then(response => response.json())
    .then(data => {
      if (showContainer) showContainer.innerHTML = data.show;
      if (indexContainer) {
        const importRow = document.querySelector(`.js-import-row-${cableData.import_id}`);
        if (!importRow) return;
        importRow.outerHTML = data.import_row;
      }
    })
    .then(() => showCableMessage(cableData))
  } else {
    showCableMessage(cableData);
  }
}

// 👇 cette méthode se chage de tout ce qu'il y a à faire lorsque la publication
// d'une sélection de produits est finalisée
const publishSelection = (cableData) => {
  const indexContainer = document.getElementById(('products-index'));
  if (indexContainer) {
    cableData.message = `${cableData.message} N'hésitez à recharger la page pour afficher les données à jour.`;
  }
  showCableMessage(cableData);
}

// 👇 cette méthode se chage de tout ce qu'il y a à faire lorsqu'une traduction
// est finalisée
const translateProductJob = (cableData) => {
  const showContainer = document.getElementById(('products-show'));
  const indexContainer = document.getElementById(('products-index'));
  if (showContainer || indexContainer) {
    cableData.message = `${cableData.message} N'hésitez à recharger la page pour afficher les données à jour.`;
  }
  showCableMessage(cableData);
}

const fetchProductImagesJob = (cableData) => {
  const fetchPictures = document.querySelector('[data-controller="fetch-product-pictures"]');
  if (fetchPictures) {
    const partials = cableData.partials;
    const controller = fetchPictures.controller;
    controller.recipientContainerTargets.forEach(recipientContainer => {
      const recipient = recipientContainer.dataset.recipient;
      recipientContainer.innerHTML = partials[recipient];
    })
    controller.backToNormalBtn();
  }
  showCableMessage(cableData);
}

export { initBgJobNotificationsChannel };
